<div class="modal-header">
	<h4 class="modal-title"><span translate>notification.selection.title</span></h4>
</div>
<div class="modal-body">
	<stepper #stepper="stepper" [listeSteps]="listeSteps"></stepper>
</div>
<div recaptcha #notificationCaptcha="reCaptcha" (onRecaptchaExpired)="onRecaptchaExpired()" (onRecaptchaSuccess)="onRecaptchaSuccess($event)" isVersion3="true" [isAuto]="false"></div>
<div class="modal-footer">
	<button mat-button (click)="bsModalRef.hide()">
		<span translate>actions.annuler</span>
	</button>

	<ng-container [ngSwitch]="stepper.getSelectedStep()?.type">
		<ng-container *ngSwitchCase="'DESTINATAIRES'">
			<button mat-stroked-button color="primary" [disabled]="!isDestinataireSelected()" (click)="stepper.getComponentInstance()?.checkDestinataires?.()">
				<span translate>actions.verifier</span>
			</button>
			<button mat-raised-button color="primary" [disabled]="!isDestinataireSelected()" (click)="stepperComponent.selectStep(listeSteps[2])">
				<span translate>actions.continuer</span>
			</button>
		</ng-container>
		<ng-container *ngSwitchCase="'MAIL_ATTACHMENTS'">
			<button mat-stroked-button color="primary" [disabled]="!stepper.getComponentInstance()?.getUploadQueue?.()?.length" (click)="stepper.getComponentInstance()?.uploadAll?.()">
				<span translate>actions.importer</span>
			</button>
			<button mat-raised-button color="primary" [disabled]="!!stepper.getComponentInstance()?.getUploadQueue?.()?.length" (click)="stepperComponent.selectStep(listeSteps[3])">
				<span translate>actions.continuer</span>
			</button>
		</ng-container>
		<ng-container *ngSwitchCase="'RECAPITULATIF'">
			<button mat-raised-button color="primary" [disabled]="!isDestinataireSelected() || !selectedMail" (click)="sendMailWithCaptcha()">
				<span translate>actions.envoyer</span>
			</button>
		</ng-container>
	</ng-container>
</div>