import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject,Subscription } from 'rxjs';
import { IMessage,IFrame } from '@stomp/stompjs';
import { HttpClient,HttpErrorResponse,HttpEvent,HttpEventType,HttpHeaders,HttpRequest } from '@angular/common/http';
import { RxStomp,RxStompState } from '@stomp/rx-stomp'
import SockJS from 'sockjs-client';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

import { AppState } from 'src/app/domain/appstate';
import { Session } from 'src/app/domain/security/session';
import { StompConfig } from 'src/app/domain/messaging/stomp-config';
import { MessagingOptions,Message,MessagingSubjects } from 'src/app/domain/messaging/messaging';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { ProgressService } from 'src/app/share/layout/progress/progress.service';
import { Result } from 'src/app/domain/common/http/result';

@Injectable({
	providedIn: 'root'
})
export class MessagingService {
	/** Token **/
	private xAuthToken: string;

	/** Configuration Stomp **/
	private stompConfig: StompConfig = {
		heartbeatIncoming: 0,
		heartbeatOutgoing: 20000,
		reconnectDelay: 10000,
		webSocketFactory: () => {
			//Connexion à la WebSocket
			return new SockJS(`${this.getBaseUrl()}/controller/wsocket`);
		}
	};

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private store: Store<AppState>,private toastrService: ToastrService,private translateService: TranslateService,private progressService: ProgressService) {
		//Sélection de la session et définition du token dans les headers de connexion
		this.store.select<Session>(s => s.session).subscribe(session => {
			//Définition du token de sécurité
			this.xAuthToken = session?.xAuthToken || null;

			//Définition des headers pour Stomp
			this.stompConfig.connectHeaders = {	'X-AUTH-TOKEN': this.xAuthToken	};
		});
	}

	/**
	 * Initialisation d'un traitement de messaging
	 */
	public init(options: MessagingOptions,updatedStompConfig?: StompConfig): MessagingObservables {
		//Mise à jour de la configuration Stomp
		const stompConfig = { ...this.stompConfig,...updatedStompConfig };

		//Initialisation des sujets retournés à l'appelant pour souscription
		const resultSubject: Subject<Result> = new Subject<Result>();
		const startSubject: Subject<string> = new Subject<string>();
		const messageSubject: Subject<any> = new Subject<any>();
		const errorSubject: Subject<any> = new Subject<any>();
		const finishSubject: Subject<Message> = new Subject<Message>();

		//Vérification de la présence d'une configuration pour gérer la progression
		if (options.progressConfig)
			//Initialisation de la progression
			options.progressConfig.reference = this.progressService.init(options.progressConfig);

		//Activation de la socket
		this.connectToSocket(stompConfig,options,{ resultSubject,startSubject,messageSubject,errorSubject,finishSubject });

		//Retour des observables sur les sujets
		return new MessagingObservables(resultSubject.asObservable(),startSubject.asObservable(),messageSubject.asObservable(),errorSubject.asObservable(),finishSubject.asObservable());
	}

	/**
	 * Connexion et activation du client
	 */
	private connectToSocket(config: StompConfig,options: MessagingOptions,subjects: MessagingSubjects) {
		let client: RxStomp;

		//Initialisation d'un client Stomp
		client = new RxStomp();

		//Configuration du client Stomp
		client.configure(config);

		//Gestion de la connexion à la socket
		client.connected$.subscribe({
			next: (state: RxStompState) => {
				//Vérification de l'état
				if (state == RxStompState.OPEN)
					//Démarrage de la session
					this.startSession(client,options,subjects);
			}
		});

		//Gestion des erreurs de socket
		client.stompErrors$.subscribe({
			next: (value: IFrame) => {
				//Transmission de l'erreur
				subjects.errorSubject.next(value);
			}
		});

		//Activation du client Stomp (ouverture de la socket)
		client.activate();
	}

	/**
	 * Démarrage de la session
	 */
	private startSession(client: RxStomp,options: MessagingOptions,subjects: MessagingSubjects) {
		//Souscription sur la récupération de la session
		const getIdSessionSubscription = client.watch('/user/messaging/getIdSession').subscribe({
			next: (message: IMessage) => {
				let idSession;

				//Récupération de l'identifiant de session
				idSession = JSON.parse(message.body).idSession;

				//Fermeture de la souscription à la session
				getIdSessionSubscription.unsubscribe();

				//Déclenchement du traitement
				this.doTraitement(client,options,subjects,idSession);
			},
			error: (error: any) => {
				//Transmission de l'erreur
				subjects.errorSubject.next(error);

				//Fermeture de la souscription à la session
				getIdSessionSubscription.unsubscribe();

				//Fermeture du client stomp
				client.deactivate();
			},complete: () => {
				//Fermeture de la souscription à la session
				getIdSessionSubscription.unsubscribe();
			}
		});

		//Appel de la récupération de l'identifiant de session
		client.publish({
			destination: '/app/Messaging/getIdSession',
			headers: {
				'X-AUTH-TOKEN': this.xAuthToken
			}
		});
	}

	/**
	 * Déclenchement du traitement
	 */
	private doTraitement(client: RxStomp,options: MessagingOptions,subjects: MessagingSubjects,idSession: string) {
		let idxParams: number;
		let topic: string;
		let url: string;
		let processSubscription: Subscription;

		//Construction du topic
		topic = options.outputPoint || options.entryPoint.replace(/^\/?controller/gm,'/messaging').replace(/\/\d+/gm,'') + '/status';

		//Souscription sur le flux de sortie
		processSubscription = client.watch(`${topic}/${idSession}`).subscribe({
			next: (message: IMessage) => {
				let progressMessage: Message;

				//Récupération de la progression
				progressMessage = JSON.parse(message.body);

				//Vérification de la présence d'une configuration pour gérer la progression
				if (options.progressConfig)
					//Rafraichissement de la progression
					this.progressService.refreshProgress(options.progressConfig.reference,progressMessage.nbProcessed,progressMessage.nbIgnored,progressMessage.nbTotal);

				//Vérification du type de message pour la transmission aux sujets
				if (progressMessage.type == 'LOG') {
					//Transmission du message
					subjects.messageSubject.next(JSON.parse(message.body));
				} else if (progressMessage.type == 'FINISH') {
					//Ajout de l'identifiant de session au message de progression
					progressMessage.idSession = idSession;

					//Transmission de la fin du traitement
					subjects.finishSubject.next(progressMessage);
				} else if (progressMessage.type == 'ERROR') {
					//Transmission de l'erreur
					subjects.errorSubject.next(JSON.parse(message.body));
				}

				//Vérification de la fin du traitement ou d'une erreur
				if (progressMessage.type == 'FINISH' || progressMessage.type == 'ERROR') {
					//Déconnexion de la souscription
					processSubscription.unsubscribe();

					//Déconnexion du client Stomp
					client.deactivate();
				}
			},
			error: (error: any) => {
				//Déconnexion de la souscription
				processSubscription.unsubscribe();

				//Transmission de l'erreur
				subjects.errorSubject.next(error);

				//Déconnexion du client Stomp
				client.deactivate();
			},
			complete: () => {
				//Déconnexion de la souscription
				processSubscription.unsubscribe();

				//Transmission de la complétion
				subjects.finishSubject.next({ type: 'COMPLETE',idSession });

				//Déconnexion du client Stomp
				client.deactivate();
			}
		});

		//Transmission du démarrage du traitement
		subjects.startSubject.next(idSession);

		//Vérification de la présence d'une configuration pour gérer la progression
		if (options.progressConfig)
			//Mise à jour de la progression
			this.progressService.updateProgress(options.progressConfig.reference,{ idSession });

		//Vérification de la présence de paramètres dans l'URL
		idxParams = options.entryPoint.indexOf('?');

		//Construction de l'URL
		url = idxParams == -1 ? options.entryPoint + '/' + idSession : options.entryPoint.substring(0,idxParams) + '/' + idSession + options.entryPoint.substring(idxParams);

		//Requête HTTP de début de traitement
		this.http.request(new HttpRequest(options.method || 'POST',url,!options.queryParams && options.params || null,{
			headers: new HttpHeaders({ ...options.headers,topic,idSession }),
			params: options.queryParams || null
		})).subscribe({
			next: (response: HttpEvent<any>) => {
				//Vérification du type de réponse
				if (response.type == HttpEventType.Response && response.status == 200)
					//Transmission du corps de la réponse
					subjects.resultSubject.next(response.body);
			},
			error: (error: HttpErrorResponse) => {
				//Vérification du statut
				if (error.status == 406 && error.headers.has('X-BLOCKED-BY-BACKEND'))
					//Affichage du message d'erreur
					this.toastrService.error(this.translateService.instant('actions.acces.nonAccepte'));

				//Transmission de l'erreur
				subjects.errorSubject.next(error);
			}
		});
	}

	/**
	 * Récupération de l'URL de base
	 */
	private getBaseUrl() {
		let url: string;

		//Récupération de l'URL complète (sans les paramètres de routage)
		url = window.location.href.substring(0,window.location.href.lastIndexOf('#') - 1);

		//Récupération de l'URL complète (sans les paramètres de requête)
		url = url.indexOf('?') != -1 ? url.substring(0,url.lastIndexOf('?') - 1) : url;

		return url;
	}
}