import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { ConversationListItemComponent } from './conversation-list-item.component';

@Component({
	templateUrl: './conversation-list.component.html'
})
export class ConversationListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,ConversationListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ConversationListItemComponent>({
			uri: () => `/controller/Chatbot/filtreConversations`,
			title: this.translateService.instant('tenant.conversation.liste.title'),
			component: ConversationListItemComponent,
			isTile: true,
			listeFilters: [{
				clef: 'date',
				type: TypeFilter.DATE
			},{
				clef: 'tenant',
				title: this.translateService.instant('search.client'),
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'tenant'
				},
				isDefault: true
			},{
				clef: 'correlationId',
				type: TypeFilter.STRING,
				isDefault: true
			},{
				clef: 'score',
				type: TypeFilter.LONG,
				listeValues: [1,2,3,4,5].map(i => ({ code: i.toString(),libelle: i.toString() })),
				isListeValuesWithButton: true
			}],
			defaultOrder: '-date',
			getKeyFieldName: () => 'idSession'
		});
	}
}