import { Component,OnInit,ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { ActionMasse } from 'src/app/domain/common/list-view/action';
import { Step } from 'src/app/domain/common/stepper/step';
import { Message,MessagingOptions } from 'src/app/domain/messaging/messaging';
import { StepperComponent } from 'src/app/share/components/stepper/stepper.component';
import { NotificationAttachmentsComponent } from './notification-attachments.component';
import { NotificationDestinatairesComponent } from './notification-destinataires.component';
import { NotificationResumeComponent } from './notification-resume.component';
import { NotificationTemplateListComponent } from './notification-template-list.component';
import { NotificationService } from './notification.service';
import { AttachmentEntity } from 'src/app/domain/attachment/attachment';
import { RecaptchaDirective } from 'src/app/share/directive/recaptcha/recaptcha.directive';

@Component({
	templateUrl: './notification.component.html'
})
export class NotificationComponent implements OnInit {
	/** Liste des étapes **/
	public listeSteps: Array<Step>;

	/** Mail sélectionné **/
	selectedMail: any = null;

	/** Liste des destinataires **/
	listeDestinataires: Array<any>;

	/** Entité courante **/
	entite: string;

	/** Action de masse **/
	actionMasse: ActionMasse;

	/** Options de messaging **/
	messagingOptions: MessagingOptions;

	/** Objet parent dont les pièces jointes peuvent être sélectionnées **/
	attachmentEntity: AttachmentEntity;

	/** Stepper **/
	@ViewChild('stepper') stepperComponent: StepperComponent;

	/** Token captcha **/
	captchaToken: string;

	/** Recaptcha **/
	@ViewChild('notificationCaptcha') recaptcha: RecaptchaDirective;

	/**
	 * Constructeur
	 */
	constructor(public bsModalRef: BsModalRef<NotificationComponent>,private notificationService: NotificationService,private toastrService: ToastrService,private translateService: TranslateService) {
		//Binding des méthodes
		this.sendMail = this.sendMail.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste des étapes
		this.listeSteps = [{
			type: 'COURRIEL',
			libelle: 'notification.selection.step.courriel',
			component: NotificationTemplateListComponent,
			retrieveComponentData: () => ({
				entite: this.entite,
				selectedMail: this.selectedMail,
				idObject: this.actionMasse.firstIdObject,
				onTemplateSelected: templateMail => {
					//Sélection du template
					this.selectedMail = templateMail;

					//Vérification de la sélection d'un template
					if (templateMail) {
						//Chargement de la liste des destinataires
						this.notificationService.retrieveListeDestinatairesForTemplate(templateMail).subscribe({
							next: listeDestinataires => {
								//Définition de la liste des destinataires
								this.listeDestinataires = listeDestinataires?.map(d => {
									//Sélection d'un destinataire principal
									d.selected = d.type == 'PRINCIPAL';

									return d;
								});

								//Passage à l'étape suivante
								this.stepperComponent.selectStep(this.listeSteps[1]);
							}
						});
					} else
						//Passage à l'étape suivante
						this.stepperComponent.selectStep(this.listeSteps[0]);
				}
			}),
			canActivate: () => true,
			isDone: () => !!this.selectedMail
		},{
			type: 'DESTINATAIRES',
			libelle: 'notification.selection.step.destinataires',
			component: NotificationDestinatairesComponent,
			retrieveComponentData: () => ({
				selectedMail: this.selectedMail,
				listeDestinataires: this.listeDestinataires,
				actionMasse: this.actionMasse,
				messagingOptions: this.messagingOptions,
				onListeDestinatairesChange: listeDestinataires => this.listeDestinataires = listeDestinataires
			}),
			canActivate: () => !!this.selectedMail,
			isDone: () => !!this.selectedMail && this.isDestinataireSelected()
		},{
			type: 'MAIL_ATTACHMENTS',
			libelle: 'notification.selection.step.mailAttachments',
			component: NotificationAttachmentsComponent,
			retrieveComponentData: () => ({
				selectedMail: this.selectedMail,
				attachmentEntity: this.attachmentEntity
			}),
			canActivate: () => !!this.selectedMail && this.isDestinataireSelected(),
			isDone: () => !!this.selectedMail && this.isDestinataireSelected() && this.selectedMail.listeLinks?.length
		},{
			type: 'RECAPITULATIF',
			libelle: 'notification.selection.step.recapitulatif',
			component: NotificationResumeComponent,
			retrieveComponentData: () => ({
				selectedMail: this.selectedMail,
				listeDestinataires: this.listeDestinataires,
				actionMasse: this.actionMasse,
				messagingOptions: this.messagingOptions
			}),
			canActivate: () => !!this.selectedMail && this.isDestinataireSelected(),
			isDone: () => false
		}];
	}

	/**
	 * Vérification de la présence de destinataires sélectionnés
	 */
	isDestinataireSelected(): boolean {
		//Vérification de la présence de destinataires sélectionnés
		return this.listeDestinataires?.some(d => d.selected);
	}

	/**
	 * Envoi de la notification
	 */
	sendMail() {
		//Fermeture de la modale
		this.bsModalRef.hide();

		//Ajout du token du captcha
		this.messagingOptions.headers = { 'X-AUTH-CAPTCHA': this.captchaToken };

		//Envoi de la notification
		this.notificationService.sendMail(this.selectedMail,this.listeDestinataires,this.actionMasse,this.messagingOptions,this.stepperComponent.getComponentInstance().getRemarque()).subscribe({
			next: (message: Message) => {
				//Vérification du message
				if (message.type == 'FINISH' && message.data?.isMailSent)
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.notification.success'));
				else
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.notification.error'));
			},
			error: () => {
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.notification.error'));
			}
		});
	}

	/**
	 * Récupération d'un token de captcha
	 */
	onRecaptchaSuccess(token) {
		//Mise à jour du token de captcha
		this.captchaToken = token;

		//Envoi du mail
		this.sendMail();
	}

	/**
	 * Expiration du reCaptcha
	 */
	onRecaptchaExpired() {
		//Mise à jour du token de captcha
		this.captchaToken = null;
	}

	/**
	 * Récupération du captcha et envoi du mail
	 */
	public sendMailWithCaptcha() {
		//Exécution de l'action
		this.recaptcha.executeAction();
	}
}