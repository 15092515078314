import { AfterContentChecked,AfterViewInit,ChangeDetectorRef,Component,EmbeddedViewRef,OnDestroy,OnInit,Renderer2,TemplateRef,ViewChild,ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { Calendar,CalendarOptions,EventApi,EventDropArg } from '@fullcalendar/core';
import interactionPlugin,{ EventResizeDoneArg } from '@fullcalendar/interaction';
import { ColCellContentArg,ColCellMountArg,ResourceLabelMountArg } from '@fullcalendar/resource';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,concat,keysIn,sortBy,uniqBy } from 'lodash-es';
import moment from 'moment-timezone';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject,Observable,Subject,Subscription,forkJoin,of } from 'rxjs';
import { debounce,debounceTime,delay,filter,first,map,skip,switchMap,tap } from 'rxjs/operators';
import { v4 } from 'uuid';

import { TenantService } from 'src/app/components/tenant/tenant.service';
import { AffectationService } from 'src/app/components/vehicule/affectation/affectation.service';
import { VehiculeService } from 'src/app/components/vehicule/vehicule.service';
import { AppState } from 'src/app/domain/appstate';
import { Filter,ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { TypePlanning } from 'src/app/domain/planning/planning';
import { TypeDroit } from 'src/app/domain/security/right';
import { ChartService } from 'src/app/share/components/chart/chart.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { MenuService } from 'src/app/share/layout/menu/menu.service';
import { LoggedUserService } from 'src/app/share/login/logged-user.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { GLOBALS } from 'src/app/utils/globals';
import { UserService } from 'src/app/components/user/user.service';
import { PlanningAffectationComponent } from './planning-affectation.component';
import { PlanningService } from './planning.service';
import { UPDATE_SELECTED_SELECTOR } from 'src/app/reducers/layout';

@Component({
	selector: 'planning',
	templateUrl: './planning.component.html'
})
export class PlanningComponent implements OnInit,AfterViewInit,AfterContentChecked,OnDestroy {
	/** Type de planning **/
	typePlanning: TypePlanning;

	/** Paramétrage du planning FullCalendar **/
	calendarOptions: CalendarOptions;

	/** Sujet sur le chargement en cours de ressources **/
	private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

	/** Indicateur de chargement **/
	isLoading$: Observable<boolean> = this.isLoadingSubject.asObservable();

	/** Indicateur de modification de la période à afficher **/
	isPeriodChanging: boolean = false;

	/** Liste des types de regroupement des véhicules **/
	listeTypesRegroupement: Array<{ code: string,mapGroupFilterKeys: { [index: string]: string },getLabel: (groupe: any) => string }>;

	/** Type de regroupement sélectionné **/
	selectedTypeRegroupement: { code: string,mapGroupFilterKeys: { [index: string]: string },getLabel: (groupe: any) => string };

	/** Liste sans contenu permettant l'affichage d'une barre de recherche **/
	liste: ListView<any,any>;

	/** Recherche actuelle **/
	private currentSearchSpec: any = null;

	/** Date courante du planning **/
	private currentDate: moment.Moment = null;

	/** Indicateur d'ouverture en cours d'une affectation **/
	private isAffectationOpened: boolean = false;

	/** Copie du type de regroupement sélectionné **/
	private savedTypeRegroupement: { code: string,mapGroupFilterKeys: { [index: string]: string },getLabel: (groupe: any) => string };

	/** Liste des groupes de ressources chargés **/
	private listeGroupes: Array<any> = [];

	/** Liste des ressources **/
	private listeResources: Array<any> = [];

	/** Liste des événements **/
	private listeEvents: Array<any> = [];

	/** Numéro de page pour les groupes **/
	private numPageGroups: number = 0;

	/** Numéro de page des ressources par groupe **/
	private mapNumPageByGroup: { [clef: string]: number } = {};

	/** Nombre d'objets à charger par page (applicable aux groupes et ressources) **/
	private nbObjetsParPage: number = 25;

	/** État d'ouverture des groupes **/
	private mapToggleStateByGroup: { [clef: string]: boolean } = {};

	/** Compteur pour l'attribution d'un identifiant aux ressources **/
	private maxId: number = 1e12;

	/** Recherche sauvegardée pour la route **/
	private savedSearch: any;

	/** Sujet sur le changement de période à afficher **/
	private onPeriodeChangeSubject: Subject<Date> = new Subject();

	/** Souscription au changement de période **/
	private onPeriodeChangeSubscription: Subscription;

	/** Souscription à l'ouverture/fermeture du menu **/
	private isMenuOpenedSubscription: Subscription;

	/** Souscription au changement de type de planning **/
	private typePlanningSelectorSubscription: Subscription;

	/** API interne du calendrier **/
	private calendarApi: Calendar;

	/** Interception du clic sur une ressource **/
	private onResourceClick: (extendedProps: { [extendedProp: string]: any }) => void;

	/** Vue contenant le sélecteur de type de regroupement **/
	private typeRegroupementSelectorViewRef: EmbeddedViewRef<any>;

	/** Contenu du sélecteur de type de regroupement **/
	private typeRegroupementSelectorNodes: Array<any>;

	/** Vue contenant le sélecteur de date **/
	private datePickerViewRef: EmbeddedViewRef<any>;

	/** Contenu du sélecteur de date **/
	private datePickerNode: any;

	/** Vue contenant le spinner **/
	private pleaseWaitViewRef: EmbeddedViewRef<any>;

	/** Contenu HTML du spinner **/
	private pleaseWaitHTML: string;

	/** Sujet sur la disponibilité de l'API du calendrier **/
	private isCalendarApiReady: BehaviorSubject<boolean> = new BehaviorSubject(false);

	/** Template du spinner **/
	@ViewChild('pleaseWaitTemplate') pleaseWaitTemplate: TemplateRef<any>;

	/** Template du sélecteur de type de regroupement **/
	@ViewChild('resourceAreaHeaderContentTemplate') resourceAreaHeaderContentTemplate: TemplateRef<any>;

	/** Template du tooltip associé aux événements **/
	@ViewChild('eventTooltipTemplate') eventTooltipTemplate: TemplateRef<any>;

	/** Template du bouton de sélection d'une date **/
	@ViewChild('datePickerButtonTemplate') datePickerButtonTemplate: TemplateRef<any>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private cookieService: CookieService,private loggedUserService: LoggedUserService,private tenantService: TenantService,private layoutService: LayoutService,private rightService: RightService,private viewContainerRef: ViewContainerRef,private renderer: Renderer2,private bsModalService: BsModalService,private menuService: MenuService,private changeDetectorRef: ChangeDetectorRef
		,private planningService: PlanningService,private chartService: ChartService,private vehiculeService: VehiculeService,private affectationService: AffectationService,private userService: UserService,private store: Store<AppState>,private activatedRoute: ActivatedRoute) {

	}

	/**
	 * Interception de la mise à jour du composant FullCalendar
	 */
	@ViewChild('calendar') set setCalendar(calendar: FullCalendarComponent) {
		//Vérification de la référence
		if (calendar && !this.calendarApi) {
			//Mise à jour de la référence
			this.calendarApi = calendar.getApi();

			//Notification de la disponibilité de l'API du calendrier
			this.isCalendarApiReady.next(true);
		}
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		let initialDate: moment.Moment;
		let doInit: Function;

		//Fonction d'initialisation/réinitialisation
		doInit = () => {
			//Récupération de la liste des types de regroupement
			this.listeTypesRegroupement = this.planningService.getListeTypesRegroupement(this.typePlanning);

			//Initialisation du type de regroupement sélectionné
			this.selectedTypeRegroupement = this.listeTypesRegroupement.find(type => type.code == this.cookieService.get(`planning.typeRegroupement.${this.typePlanning}`)) || this.listeTypesRegroupement[0];

			//Copie du type de regroupement avant modification
			this.savedTypeRegroupement = this.selectedTypeRegroupement;

			//Initialisation de la date affichée dans le planning
			this.currentDate = initialDate || moment().startOf('week');

			//Récupération de la recherche sauvegardée
			this.savedSearch = JSON.parse(sessionStorage.getItem(`savedSearch.${this.layoutService.getExtendedRouteData()?.state}`));

			//Initialisaton de la barre de recherche
			this.initListe();

			//Vérification du droit de consultation sur les véhicules
			if (this.typePlanning === TypePlanning.VEHICULE && this.rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'consultation')) {
				//Définition de l'intercepteur de clics sur les ressources
				this.onResourceClick = extendedProps => {
					//Redirection vers le véhicule
					this.layoutService.goToByState('listeVehicules-loadVehicule',{
						routeParams: {
							idVehicule: extendedProps.originalId
						},
						withGoBack: true
					});
				};
			} else if (this.typePlanning === TypePlanning.USER && this.rightService.hasRight(TypeDroit.ADMIN_UTILISATEUR,'consultation')) {
				//Définition de l'intercepteur de clics sur les ressources
				this.onResourceClick = extendedProps => {
					//Redirection vers le user
					this.layoutService.goToByState('listeUsers-user',{
						routeParams: {
							idUser: extendedProps.originalId
						},
						withGoBack: true
					});
				};
			}
		}

		//Abonnement au sélecteur de type de planning
		this.typePlanningSelectorSubscription = this.store.select<any>(state => state.layout?.selectedSelector).pipe(
			filter(l => l?.state === 'planning'),
			map(l => l?.selector),
			filter(selectedSelector => !!this.typePlanning && selectedSelector != this.typePlanning)
		).subscribe(selectedSelector => {
			//Définition du type de planning sélectionné
			this.typePlanning = selectedSelector;

			//Définition de l'URL
			this.layoutService.replaceUrlWith({ typePlanning: this.typePlanning });

			//Suppression de la liste
			this.liste = null;

			//Conservation de la date actuellement affichée
			initialDate = this.currentDate;

			//Mise en cycle
			setTimeout(() => {
				//Réinitialisation
				doInit();

				//Remise à zéro des ressources du planning
				this.resetPlanningResources(false);

				//Rechargement de zéro des données
				this.showMoreGroups();

				//Attente de la disponibilité de l'API du calendrier
				this.isCalendarApiReady.asObservable().pipe(filter(isReady => !!isReady),first()).subscribe(() => {
					//Mise à jour du paramétrage du calendrier
					this.calendarApi.setOption('selectOverlap',this.typePlanning == TypePlanning.USER);
				});
			});
		});

		//Définition du type de planning
		this.typePlanning = this.activatedRoute.snapshot.params.typePlanning || TypePlanning.VEHICULE;

		//Définition du sélecteur
		this.store.dispatch({
			type: UPDATE_SELECTED_SELECTOR,
			payload: {
				state: 'planning',
				selector: this.typePlanning
			}
		});

		//Définition de la date initiale à afficher en fonction du paramètre fourni
		initialDate = history.state?.initialDate ? moment(history.state.initialDate).startOf('week') : null;

		//Initialisation
		doInit();

		//Écoute de l'ouverture/fermeture du menu
		this.isMenuOpenedSubscription = this.menuService.isOpened$.pipe(skip(1),delay(400)).subscribe(() => {
			//Mise à jour du planning
			this.calendarApi ? this.calendarApi.updateSize() : this.showMoreGroups(true);
		});

		//Écoute du changement de période (avec mécanisme de limitation des requêtes superflues)
		this.onPeriodeChangeSubscription = this.onPeriodeChangeSubject.pipe(
			tap(() => {
				//Début du changement de période
				this.isPeriodChanging = true;
			}),
			debounceTime(300),
			debounce(() => this.isLoading$.pipe(filter(isLoading => !isLoading)))
		).subscribe(date => {
			//Fin du changement de période
			this.isPeriodChanging = false;

			//Exécution du changement de date
			this.onPeriodChange(date);
		});

		//Attente de la disponibilité des éléments du DOM
		setTimeout(() => {
			//Initialisation du calendrier
			this.initCalendar();

			//Vérification de l'absence de recherche initiale
			if (!this.savedSearch?.listeSelectedFilters?.length && !this.savedSearch?.extraData)
				//Chargement initial des données
				this.showMoreGroups();
		});
	}

	/**
	 * Initialisation de la vue
	 */
	ngAfterViewInit() {
		//Création de la vue du sélecteur de type de regroupement
		this.typeRegroupementSelectorViewRef = this.viewContainerRef.createEmbeddedView(this.resourceAreaHeaderContentTemplate);

		//Détection des changements
		this.typeRegroupementSelectorViewRef.detectChanges();

		//Création de la vue du sélecteur de date
		this.datePickerViewRef = this.viewContainerRef.createEmbeddedView(this.datePickerButtonTemplate);

		//Détection des changements
		this.datePickerViewRef.detectChanges();

		//Création de la vue du spinner
		this.pleaseWaitViewRef = this.viewContainerRef.createEmbeddedView(this.pleaseWaitTemplate);

		//Détection des changements
		this.pleaseWaitViewRef.detectChanges();
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked(): void {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy() {
		//Annulation des souscriptions
		this.onPeriodeChangeSubscription?.unsubscribe();
		this.isMenuOpenedSubscription?.unsubscribe();
		this.typePlanningSelectorSubscription?.unsubscribe();
	}

	/**
	 * Interception du changement de type de regroupement
	 */
	onTypeRegroupementChange() {
		//Vérification de la modification
		if (this.selectedTypeRegroupement != this.savedTypeRegroupement) {
			//Remise à zéro des ressources du planning
			this.resetPlanningResources(true);

			//Chargement des données
			this.showMoreGroups(false,null,this.currentSearchSpec);

			//Enregistrement de la préférence
			this.cookieService.set(`planning.typeRegroupement.${this.typePlanning}`,this.selectedTypeRegroupement.code);

			//Mémorisation du nouveau type de regroupement sélectionné
			this.savedTypeRegroupement = this.selectedTypeRegroupement;
		}
	}

	/**
	 * Génération du contexte de la pop-up de sélection d'une date
	 */
	getDatePickerPopoverContext(): any {
		//Retour du contexte de la pop-up
		return {
			$implicit: {
				date: cloneDeep(this.currentDate)
			}
		};
	}

	/**
	 * Interception de la sélection d'une date via le bouton d'en-tête
	 */
	onDateSelected(date: Date) {
		//Affichage de la date sélectionnée
		this.calendarApi.gotoDate(date);
	}

	/**
	 * Initialisation du calendrier
	 */
	private initCalendar() {
		//Définiton du paramétrage du calendrier
		this.calendarOptions = {
			plugins: [resourceTimelinePlugin,interactionPlugin],
			initialView: 'resourceTimelineWeek',
			schedulerLicenseKey: '0744770213-fcs-1698857875',
			initialDate: this.currentDate.toDate(),
			locale: 'fr',
			firstDay: 1,
			weekends: true,
			editable: true,
			selectable: true,
			selectOverlap: this.typePlanning == TypePlanning.USER,
			nowIndicator: true,
			slotDuration: '12:00',
			eventDurationEditable: true,
			buttonText: {
				today: this.translateService.instant('rule.dateFunction.DATE_TODAY')
			},
			headerToolbar: {
				left: 'today prev,next',
				center: 'title',
				right: ''
			},
			resourceGroupField: 'groupUuid',
			resourcesInitiallyExpanded: false,
			resources: (_fetchInfo,successCallback) => {
				//Retour de la liste des ressources
				successCallback(this.listeResources);
			},
			events: (_fetchInfo,successCallback) => {
				//Retour de la liste des événements
				successCallback(this.listeEvents);
			},
			datesSet: arg => {
				//Demande de mise à jour du planning
				this.onPeriodeChangeSubject.next(arg.start);
			},
			resourceAreaHeaderClassNames: 'resource-area-header',
			resourceAreaHeaderContent: () => {
				//Récupération du contenu de l'en-tête de la colonne des ressources
				return this.getResourceAreaHeaderContent();
			},
			resourceGroupLabelDidMount: arg => {
				//Interception de l'affichage du groupe
				this.resourceGroupLabelDidMount(arg);
			},
			resourceGroupLabelClassNames: arg => {
				//Récupération des classes à ajouter au groupe
				return this.getResourceGroupLabelClassNames(arg);
			},
			resourceGroupLabelContent: arg => {
				//Récupération du label d'un groupe
				return this.getResourceGroupLabelContent(arg);
			},
			resourceLabelDidMount: arg => {
				//Interception de l'affichage de la ressource
				this.resourceLabelDidMount(arg);
			},
			resourceLabelClassNames: arg => {
				//Vérification du bouton pour afficher plus de résultats
				if (arg.resource.extendedProps.typeFictiveResource == 'SHOW_MORE')
					//Ajout de la classe
					return 'show-more';
				else if (arg.resource.extendedProps.typeFictiveResource == 'SPINNER')
					//Ajout de la classe
					return 'spinner';
				else if (this.onResourceClick)
					//Ajout de la classe
					return 'link';
			},
			resourceLabelContent: arg => {
				//Vérification que le composant 'please-wait' doit être initialisé
				if (!this.pleaseWaitHTML) {
					//Récupération du contenu du composant 'please-wait'
					this.pleaseWaitHTML = this.pleaseWaitViewRef?.rootNodes[0].innerHTML;

					//Destruction de la vue
					this.pleaseWaitViewRef?.destroy();
				}

				//Vérification du type de ressource
				if (arg.resource.extendedProps.typeFictiveResource == 'SPINNER') {
					//Retour du contenu de la cellule
					return { html: this.pleaseWaitHTML };
				} else
					//Retour du libellé de la ressource
					return arg.resource.title;
			},
			eventDidMount: arg => {
				let tipData: { affectation: any };
				let tipViewRef: EmbeddedViewRef<any>;

				//Récuération des données du tooltip pour l'événement
				tipData = this.getEventDataForTooltip(arg.event);

				//Vérification de la présence de données
				if (tipData) {
					//Création de la vue
					tipViewRef = this.viewContainerRef.createEmbeddedView(this.eventTooltipTemplate,{ $implicit: tipData.affectation });

					//Attente de la compilation de la vue
					setTimeout(() => {
						//Ajout du tooltip à l'élément
						GLOBALS.$(arg.el).tooltip({ title: tipViewRef.rootNodes[0].innerHTML,html: true,container: 'body',placement: 'bottom' });

						//Destruction de la vue
						tipViewRef.destroy();
					});
				}
			},
			eventWillUnmount: arg => {
				//Masquage du tooltip
				GLOBALS.$(arg.el).tooltip('hide');
			},
			eventClick: arg => {
				//Vérification de la présence des données nécessaires
				if (arg.event)
					//Exécution de l'action
					this.showAffectation(arg.event.extendedProps.resourceOriginalId,arg.event.id);
			},
			select: arg => {
				//Affichage de la création de l'affectation
				this.showAffectation(arg.resource.extendedProps.originalId,null,{
					dateDebut: arg.start,
					dateFin: moment.duration(moment(arg.end).diff(moment(arg.start))).asHours() > 12 ? arg.end : null
				});
			},
			eventDrop: arg => {
				//Gestion du déplacement de l'événement
				this.onEventDrop(arg);
			},
			eventResize: arg => {
				//Gestion du redimensionnement de l'événement
				this.onEventDrop(arg);
			},
			viewDidMount: () => {
				let fcToolbarChunk: HTMLDivElement;

				//Attente de la disponibilité des éléments du DOM
				setTimeout(() => {
					//Vérification que le bouton de sélection de date doit être initialisé
					if (!this.datePickerNode)
						//Définition du bouton de sélection de la date
						this.datePickerNode = this.datePickerViewRef.rootNodes[0];

					//Récupération de la zone de gauche de l'en-tête du calendrier
					fcToolbarChunk = this.calendarApi.el.querySelector('.fc-header-toolbar .fc-toolbar-chunk');

					//Affichage du bouton de sélection de la date
					this.renderer.appendChild(fcToolbarChunk,this.datePickerNode);
				});
			}
		};
	}

	/**
	 * Initialisation de la liste virtuelle
	 */
	private initListe() {
		let isInitialRefresh: boolean = true;

		//Définition de la liste virtuelle (pour affichage de la barre de recherche)
		this.liste = new ListView<any,any>({
			uri: null,
			component: null,
			isLoadingDisabled: true,
			isContentHidden: true,
			title: this.translateService.instant('planning.title'),
			placeholder: this.typePlanning == TypePlanning.VEHICULE ? 'planning.search.searchField' : undefined,
			selectedSelector: this.typePlanning,
			listeSelectors: keysIn(TypePlanning).map(typePlanning => ({ code: typePlanning,libelle: this.translateService.instant(`planning.typePlanning.${typePlanning}`) })),
			listeFilters: [{
				clef: 'societe',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'societe',
					fieldForDefault: '*reference,*libelle',
					isBasket: true
				},
				isDefault: true
			},this.typePlanning === TypePlanning.USER && {
				clef: 'service',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'service',
					fieldForDefault: '*reference,*libelle',
					isBasket: true
				},
				isDefault: true,
				isKeptForSelector: TypePlanning.USER
			},this.typePlanning === TypePlanning.USER && {
				clef: '',
				title: this.translateService.instant('search.user'),
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'user',
					isBasket: true
				},
				isDefault: true,
				isKeptForSelector: TypePlanning.USER
			},this.typePlanning === TypePlanning.VEHICULE && {
				clef: '',
				title: this.translateService.instant('search.vehicule'),
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'vehicule',
					isBasket: true
				},
				isDefault: true,
				isKeptForSelector: TypePlanning.VEHICULE
			},this.typePlanning === TypePlanning.VEHICULE && {
				clef: 'genre',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'genre',
					isBasket: true,
					filter: {
						typeSource: 'EXTERNE_IMMATRICULATION'
					}
				},
				isDefault: true,
				isKeptForSelector: TypePlanning.VEHICULE
			},this.typePlanning === TypePlanning.VEHICULE && {
				clef: 'modele',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'modele',
					isBasket: true,
					filter: {
						typeSource: 'EXTERNE_IMMATRICULATION'
					}
				},
				isDefault: true,
				isKeptForSelector: TypePlanning.VEHICULE
			},this.typePlanning === TypePlanning.VEHICULE && {
				clef: 'pool',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'pool',
					fieldForDefault: '*reference,*libelle',
					isBasket: true
				},
				isDefault: true,
				isKeptForSelector: TypePlanning.VEHICULE
			},this.typePlanning === TypePlanning.VEHICULE && {
				clef: 'carburant',
				title: this.translateService.instant('search.energie'),
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'carburant',
					isBasket: true
				},
				isDefault: true,
				isKeptForSelector: TypePlanning.VEHICULE
			},this.typePlanning === TypePlanning.VEHICULE && {
				clef: 'disponibilite',
				title: this.translateService.instant('planning.search.disponibilite'),
				type: TypeFilter.PERIOD,
				selectFilterLinkTitle: this.translateService.instant('planning.search.linkTitle'),
				isKeptForSelector: TypePlanning.VEHICULE
			},this.typePlanning === TypePlanning.USER && {
				clef: 'categorie',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'userCategorie',
					isBasket: true,
					fieldForDefault: '*reference,*libelle'
				},
				isKeptForSelector: TypePlanning.USER
			}].filter(f => !!f),
			onRefresh: (liste,{ searchSpec }) => {
				//Vérification de la nécessité de rafraichir les données du planning suite à une modification de la recherche
				if (!isInitialRefresh || this.savedSearch != null) {
					//Attente de la fin du chargement éventuellement en cours
					forkJoin([
						this.isLoading$.pipe(filter(isLoading => !isLoading),first()),
						this.isCalendarApiReady.asObservable().pipe(filter(isReady => !!isReady),first())
					]).subscribe(() => {
						let dateDebutPeriode: moment.Moment;

						//Vérification de l'ajout/modification d'une période de disponibilité dans la recherche
						dateDebutPeriode = this.checkUpdatedPeriodeDisponibilite(this.currentSearchSpec,searchSpec);

						//Réinitialisation des ressources du planning
						this.resetPlanningResources();

						//Vérification d'une date de début de période
						if (dateDebutPeriode)
							//Mise à jour de la date courante
							this.currentDate = dateDebutPeriode;

						//Rafraichissement de la page
						this.showMoreGroups(false,null,searchSpec);

						//Enregistrement de la recherche actuelle
						this.currentSearchSpec = cloneDeep(searchSpec);
					});
				}

				//Indication de la fin d'initialisation de la liste
				isInitialRefresh = false;
			}
		});
	}

	/**
	 * Demande de (re)chargement d'une page de groupes de ressources
	 */
	private showMoreGroups(isRefreshSamePages?: boolean,date?: Date,searchSpec?: any): void {
		let isInitialLoad: boolean;
		let dateDebut: moment.Moment;
		let dateFin: moment.Moment;

		//Début du chargement
		this.isLoadingSubject.next(true);

		//Vérification qu'il s'agit du premier chargement
		isInitialLoad = this.numPageGroups == 0;

		//Définition de la date de début de période
		dateDebut = date ? moment(date).startOf('week') : moment(this.currentDate);

		//Définition de la date de fin de période
		dateFin = moment(dateDebut).endOf('week');

		//Retrait de la ressource fictive permettant d'afficher plus de groupes
		this.removeFictiveResource();

		//Récupération des groupes de véhicules
		this.planningService.filtreEntitesBy(this.typePlanning,this.selectedTypeRegroupement.code,moment.utc(dateDebut),moment.utc(dateFin),searchSpec,isRefreshSamePages ? 0 : this.numPageGroups,this.nbObjetsParPage * (isRefreshSamePages && this.numPageGroups || 1)).pipe(switchMap(pageGroupes => {
			let listeObservables: Array<Observable<any>>;

			//Vérification du contexte de chargement
			if (!isRefreshSamePages)
				//Incrémentation du numéro de page des groupes
				this.numPageGroups++;

			//Définition de la liste des groupes
			this.listeGroupes = isRefreshSamePages ? pageGroupes.content : this.listeGroupes.concat(pageGroupes.content);

			//Parcours des groupes
			this.listeGroupes.forEach((groupe,index) => {
				let oldUuid: string;

				//Mémorisation de l'ancien identifiant unique du groupe
				oldUuid = groupe.uuid;

				//Mémorisation de l'index du groupe dans la liste
				groupe.order = index;

				//Atribution d'un nouvel identifiant unique au groupe (permet de forcer le rafraichissement de son affichage)
				groupe.uuid = v4().toString();

				//Définition de la clé composite associée au groupe (utilisée pour mémoriser la pagination sur les groupes)
				groupe.clef = groupe.idGroupe + '-' + groupe.libelleParent + '-' + groupe.libelleGroupe;

				//Vérification que le groupe vient d'être chargé
				if (!oldUuid)
					//Ajout d'une ressource fictive dans le groupe afin de le faire apparaitre
					this.addFictiveResource(groupe,'SPINNER');
				else
					//Recréation du lien entre le groupe et ses ressources
					this.listeResources.filter(r => r.groupUuid == oldUuid).forEach(r => r.groupUuid = groupe.uuid);
			});

			//Vérification qu'il reste des groupes à charger
			if (!pageGroupes.last)
				//Ajout d'une ressource fictive permettant d'afficher plus de groupes
				this.addFictiveResource(null,'SHOW_MORE');

			//Vérification du contexte de chargement des groupes
			if (isInitialLoad)
				//Chargement des ressources du premier groupe
				listeObservables = [this.loadMoreResources(pageGroupes.content[0],false,date,searchSpec)];
			else if (isRefreshSamePages)
				//Rechargement des ressources de l'ensemble des groupes déjà ouverts
				listeObservables = pageGroupes.content.map(groupe => this.loadMoreResources(groupe,true,date,searchSpec));
			else
				//Aucune ressource supplémentaire à charger
				listeObservables = [of(true)];

			//Exécution en parallèle des requêtes de récupération des ressources au sein des groupes
			return forkJoin(listeObservables);
		})).subscribe({
			next: () => {
				//Vérification du changement de date
				if (date)
					//Mise à jour de la date courante
					this.currentDate = dateDebut;

				//Mise à jour de la période
				this.calendarOptions.initialDate = dateDebut.toDate();

				//Tri des ressources avant leur affichage
				this.sortResources();

				//Actualisation de l'affichage du planning
				this.onRefresh();
			},
			complete: () => {
				//Fin du chargement
				this.isLoadingSubject.next(false);
			}
		});
	}

	/**
	 * Demande du chargement d'une page de ressources supplémentaires dans un groupe
	 */
	private showMoreResources(groupe): void {
		//Début du chargement
		this.isLoadingSubject.next(true);

		//Chargement d'une page de ressources pour le groupe donné
		this.loadMoreResources(groupe,null,null,this.savedSearch).subscribe({
			next: () => {
				//Tri des ressources avant leur affichage
				this.sortResources();

				//Actualisation de l'affichage du planning
				this.onRefresh();
			},
			complete: () => {
				//Fin du chargement
				this.isLoadingSubject.next(false);
			}
		});
	}

	/**
	 * Chargement d'une page de ressources
	 */
	private loadMoreResources(groupe: any,isRefreshSamePages: boolean,date: Date,searchSpec: any): Observable<boolean> {
		let dateDebut: moment.Moment;
		let dateFin: moment.Moment;
		let listeFiltersForGroupe: Array<any>;
		let searchSpecCopy: any;

		//Vérification de la nécessité de charger les ressources du groupe
		if (groupe && (!isRefreshSamePages || this.mapNumPageByGroup[groupe.clef])) {
			//Définition de la date de début de période
			dateDebut = date ? moment(date).startOf('week') : moment(this.currentDate);

			//Définition de la date de fin de période
			dateFin = moment(dateDebut).endOf('week');

			//Création de la recherche
			searchSpecCopy = cloneDeep(searchSpec) || {};

			//Génération de la liste des filtres pour le groupe
			listeFiltersForGroupe = this.planningService.generateListeFiltersForGroupe(this.selectedTypeRegroupement,groupe);

			//Génération des filtres associés au groupe
			searchSpecCopy.listeFilter = (searchSpecCopy.listeFilter?.length || listeFiltersForGroupe?.length || this.currentSearchSpec?.listeFilter?.length) ? uniqBy(concat(searchSpecCopy.listeFilter || [],listeFiltersForGroupe || [],this.currentSearchSpec?.listeFilter || []),item => item.clef + (item.valeur || '')) : [];

			//Définition de la pagination
			searchSpecCopy.numPage = isRefreshSamePages ? 0 : groupe && this.mapNumPageByGroup[groupe.clef] || 0;
			searchSpecCopy.nbObjetsParPage = this.nbObjetsParPage * (isRefreshSamePages && groupe && this.mapNumPageByGroup[groupe.clef] || 1);

			//Retour de la recherche des véhicules
			return this.planningService.filtreEntites(this.typePlanning,moment.utc(dateDebut),moment.utc(dateFin),searchSpecCopy).pipe(
				tap(data => {
					//Vérification que l'on ajoute des ressources au groupe
					if (!isRefreshSamePages && groupe)
						//Incrémentation du numéro de page sur les ressources du groupe
						this.mapNumPageByGroup[groupe.clef] = (this.mapNumPageByGroup[groupe.clef] || 0) + 1;

					//Retrait de la ressource fictive du groupe
					this.removeFictiveResource(groupe);

					//Vérification du type de planning
					if (this.typePlanning === TypePlanning.VEHICULE)
						//Génération des ressources à partir des véhicules obtenus
						this.generateResourcesFromVehicules(groupe,data.listeEntites.content,dateDebut,dateFin);
					else if (this.typePlanning === TypePlanning.USER)
						//Génération des ressources à partir des utilisateurs obtenus
						this.generateResourcesFromUsers(groupe,data.listeEntites.content,dateDebut,dateFin);

					//Vérification qu'il est possible d'afficher plus de ressources dans le groupe
					if (!data.listeEntites.last)
						//Ajout d'une ressource fictive pour permettre le chargement de véhicules supplémentaires
						this.addFictiveResource(groupe,'SHOW_MORE');
				}),
				map(data => !!data)
			);
		} else
			//Aucun chargement nécessaire
			return of(true);
	}

	/**
	 * Tri des ressources pour leur affichage (nécessaire car le tri natif des ressources groupées par fullCalendar n'est pas au point)
	 */
	private sortResources() {
		//Tri des ressources
		this.listeResources = sortBy(this.listeResources,['groupOrder','isFictive','title']);

		//Parcours des ressources triées
		this.listeResources.forEach(resource => {
			//Attribution d'un l'identifiant à la ressource (champ utilisé par défaut par fullCalendar pour le tri)
			resource.id = ++this.maxId;

			//Vérification que la ressource n'est pas fictive
			if (!resource.isFictive)
				//Établissement du lien entre la ressource et ses événements
				this.listeEvents.filter(e => e.extendedProps.resourceOriginalId == resource.originalId).forEach(e => e.resourceId = resource.id);
		});
	}

	/**
	 * Interception de la modification de la période à afficher
	 */
	private onPeriodChange(date: Date) {
		//Vérification du changement de date
		if (!moment(date).isSame(this.currentDate)) {
			//Purge de la liste des ressources
			this.listeResources.length = 0;

			//Purge de la liste des événements
			this.listeEvents.length = 0;

			//Chargement des données avec conservation des paginations actuelles
			this.showMoreGroups(true,date,this.currentSearchSpec);
		}
	}

	/**
	 * Vérification de l'ajout/modification d'un critère de période de disponibilité
	 */
	private checkUpdatedPeriodeDisponibilite(oldSearchSpec: any,newSearchSpec: any): moment.Moment {
		let oldFilter: Filter;
		let newFilter: Filter;
		let dateDebutPeriode: moment.Moment;

		//Recherche des filtres de type 'période'
		oldFilter = oldSearchSpec?.listeFilter?.find(f => f.type == TypeFilter.PERIOD);
		newFilter = newSearchSpec?.listeFilter?.find(f => f.type == TypeFilter.PERIOD);

		//Vérification d'un changement de filtre de période
		if (!oldFilter && newFilter || oldFilter && newFilter && oldFilter.dateDebut != newFilter.dateDebut) {
			//Définition de la date de début de période
			dateDebutPeriode = moment(newFilter.dateDebut).startOf('day');

			//Déclenchement du changement de date dans la vue du planning
			this.calendarApi?.gotoDate(dateDebutPeriode.toDate());
		}

		//Retour de la nouvelle date de début de période
		return dateDebutPeriode;
	}

	/**
	 * Remise à zéro des ressources du planning
	 */
	private resetPlanningResources(isKeepSavedSearch?: boolean) {
		//Remise à zéro des paginations
		this.numPageGroups = 0;
		this.mapNumPageByGroup = {};

		//Remise à zéro des indicateurs d'ouverture des groupes
		this.mapToggleStateByGroup = {};

		//Purge de la liste des ressources
		this.listeResources.length = 0;

		//Purge de la liste des événements
		this.listeEvents.length = 0;

		//Purge de la liste des groupes
		this.listeGroupes.length = 0;

		//Réinitialisation de la recherche actuelle
		this.currentSearchSpec = isKeepSavedSearch ? this.currentSearchSpec : null;
	}

	/**
	 * Ajout d'une ressource fictive à un groupe
	 */
	private addFictiveResource(groupe: any,type: 'SHOW_MORE' | 'SPINNER') {
		//Vérification du groupe au sein duquel rajouter une ressource fictive
		if (groupe) {
			//Ajout d'une ressource fictive au sein du groupe
			this.listeResources.push({
				title: type == 'SHOW_MORE' ? this.translateService.instant('planning.showMore') : '',
				groupOrder: groupe.order,
				groupUuid: groupe.uuid,
				isFictive: 1,
				typeFictiveResource: type
			});
		} else {
			//Ajout d'une ressource fictive de type 'show-more' pour afficher un groupe supplémentaire
			this.listeResources.push({
				title: this.translateService.instant('planning.showMore'),
				groupOrder: this.listeGroupes.length,
				groupUuid: v4().toString(),
				isFictive: 1,
				typeFictiveResource: type
			});
		}
	}

	/**
	 * Retrait de la ressource fictive d'un groupe
	 */
	private removeFictiveResource(groupe?: any) {
		let index: number;

		//Récupération de l'index de la ressource fictive dans le groupe fourni
		index = this.listeResources.findIndex(r => r.isFictive && (groupe ? r.groupUuid == groupe.uuid : this.listeGroupes.findIndex(g => g.uuid == r.groupUuid) == -1));

		//Vérification de l'index
		if (index > -1)
			//Retrait de la ressource fictive
			this.listeResources.splice(index,1);
	}

	/**
	 * Génération des ressources et des évènements à partir des véhicules chargés
	 */
	private generateResourcesFromVehicules(groupe: any,listeVehicules: Array<any>,dateDebut: moment.Moment,dateFin: moment.Moment) {
		//Parcours de la liste des véhicules
		listeVehicules.forEach(vehicule => {
			//Ajout du véhicule
			this.listeResources.push({
				title: this.getVehiculeTitle(vehicule,true),
				groupOrder: groupe.order,
				groupUuid: groupe.uuid,
				isFictive: 0,
				originalId: vehicule.idVehicule
			});
		});

		//Parcours de la liste des véhicules
		listeVehicules.forEach(vehicule => {
			let dateDebutContrat: moment.Moment;
			let dateFinContrat: moment.Moment;
			let userTimezone: string;

			//Parcours des listes des affectations
			vehicule.listeSelectedAffectations?.forEach(affectation => {
				//Ajout de l'affectation
				this.listeEvents.push(this.getEventFromAffectation(affectation,vehicule.idVehicule,dateFin));
			});

			//Vérification de la date d'entrée du véhicule
			if (vehicule.dateEntree && vehicule.dateEntree > dateDebut.valueOf() && vehicule.dateEntree < dateFin.valueOf()) {
				//Ajout de l'affectation fictive d'entrée
				this.listeEvents.push({
					id: vehicule.idVehicule + '-Start',
					resourceId: null,
					start: dateDebut.valueOf(),
					end: vehicule.dateEntree,
					color: this.getEventColor('HORS_PARC'),
					display: 'background',
					overlap: false,
					extendedProps: {
						resourceOriginalId: vehicule.idVehicule
					}
				});
			}

			//Vérification de la date de sortie du véhicule
			if (vehicule.dateSortie && vehicule.dateSortie > dateDebut.valueOf() && vehicule.dateSortie < dateFin.valueOf()) {
				//Ajout de l'affectation fictive de fin
				this.listeEvents.push({
					id: vehicule.idVehicule + '-Fin',
					resourceId: null,
					start: vehicule.dateSortie,
					end: dateFin.valueOf(),
					color: this.getEventColor('HORS_PARC'),
					display: 'background',
					overlap: false,
					extendedProps: {
						resourceOriginalId: vehicule.idVehicule
					}
				});
			}

			//Vérification du type de contrat
			if (['LOCATION_LONGUE_DUREE','LOCATION_MOYENNE_DUREE'].includes(vehicule.financement?.type?.mode)) {
				//Récupération du fuseau horaire du tenant
				userTimezone = this.tenantService.getListeTimezones().find(t => t.code == this.loggedUserService.getUser()?.timezone)?.libelle;

				//Récupération de la date de début du contrat (utilisation du fuseau horaire du tenant pour l'heure)
				dateDebutContrat = userTimezone ? moment.utc(vehicule.financement.dateDebut).tz(userTimezone,true) : moment(vehicule.financement.dateDebut);

				//Récupération de la date de fin du contrat (utilisation du fuseau horaire du tenant pour l'heure)
				dateFinContrat = vehicule.financement.dateFin ? userTimezone && moment.utc(vehicule.financement.dateFin).tz(userTimezone,true) || moment(vehicule.financement.dateFin) : null;

				//Vérification de la date de début de contrat du véhicule
				if (dateDebutContrat.isAfter(dateDebut) && dateDebutContrat.valueOf() > vehicule.dateEntree) {
					//Ajout de l'affectation fictive de fin de contrat
					this.listeEvents.push({
						id: vehicule.idVehicule + '-Contrat-Start',
						resourceId: null,
						start: vehicule.dateEntree,
						end: dateDebutContrat.isBefore(dateFin) ? dateDebutContrat.valueOf() : dateFin.valueOf(),
						color: this.getEventColor('HORS_CONTRAT'),
						display: 'background',
						overlap: false,
						extendedProps: {
							resourceOriginalId: vehicule.idVehicule
						}
					});
				}

				//Vérification de la date de fin de contrat du véhicule
				if (dateFinContrat && dateFinContrat.isBefore(dateFin) && (!vehicule.dateSortie || dateFinContrat.valueOf() < vehicule.dateSortie)) {
					//Ajout de l'affectation fictive de fin de contrat
					this.listeEvents.push({
						id: vehicule.idVehicule + '-Contrat-Fin',
						resourceId: null,
						start: dateFinContrat.valueOf(),
						end: vehicule.dateSortie && vehicule.dateSortie < dateFin.valueOf() ? vehicule.dateSortie : dateFin.valueOf(),
						color: this.getEventColor('HORS_CONTRAT'),
						display: 'background',
						overlap: false,
						extendedProps: {
							resourceOriginalId: vehicule.idVehicule
						}
					});
				}
			}
		});
	}

	/**
	 * Génération des ressources et des évènements à partir des utilisateurs chargés
	 */
	private generateResourcesFromUsers(groupe: any,listeUsers: Array<any>,dateDebut: moment.Moment,dateFin: moment.Moment) {
		//Parcours de la liste des utilisateurs
		listeUsers.forEach(user => {
			//Ajout du user
			this.listeResources.push({
				title: this.getUserTitle(user),
				groupOrder: groupe.order,
				groupUuid: groupe.uuid,
				isFictive: 0,
				originalId: user.idUser
			});

			//Parcours des listes des affectations
			user.listeSelectedAffectations?.forEach(affectation => {
				//Ajout de l'affectation
				this.listeEvents.push(this.getEventFromAffectation(affectation,user.idUser,dateFin));
			});
		});
	}

	/**
	 * Affichage de l'affectation
	 */
	private showAffectation(idEntity: string | number,idAffectation: string | number,affectationToCreate?: any) {
		let bsModalRef: BsModalRef<PlanningAffectationComponent>;
		let loadDataObservable: Observable<any>;

		//Vérification qu'une affectation est déjà en cours d'ouverture
		if (this.isAffectationOpened)
			//Ne pas continuer
			return;

		//Indication qu'une affectation est en cours d'ouverture
		this.isAffectationOpened = true;

		//Vérification de l'identifiant
		if (idAffectation) {
			//Définition de la requête de chargement de l'affectation existante
			loadDataObservable = this.affectationService.loadAffectation(idAffectation as number).pipe(map(result => result?.data?.affectation));
		} else if (this.typePlanning === TypePlanning.VEHICULE) {
			//Définition de la requête de chargement du véhicule
			loadDataObservable = this.vehiculeService.loadVehicule(idEntity as number).pipe(map(result => {
				//Création d'une nouvelle affectation
				return result?.data?.vehicule ? {
					...affectationToCreate,
					vehicule: result.data.vehicule,
					synchroImputationUser: true
				} : null;
			}));
		} else if (this.typePlanning === TypePlanning.USER) {
			//Définition de la requête de chargement de l'utilisateur
			loadDataObservable = this.userService.loadUser(idEntity as number).pipe(map(result => {
				//Création d'une nouvelle affectation
				return result?.data?.user ? {
					...affectationToCreate,
					user: result.data.user,
					synchroImputationUser: true
				} : null;
			}));
		}

		//Chargement de l'affectation et affichage de la pop-up
		loadDataObservable.pipe(
			filter(affectation => !!affectation),
			switchMap(affectation => {
				let modalUuid: string;

				//Génération d'un identifiant unique pour la pop-up
				modalUuid = v4().toString();

				//Interception de l'affichage de la pop-up d'édition de l'affectation
				this.bsModalService.onShown.pipe(filter(data => data.id == modalUuid),first()).subscribe(() => {
					//Rétablissement de l'indicateur d'ouverture de l'affectation
					this.isAffectationOpened = false;
				});

				//Affichage de la pop-up d'édition de l'affectation
				bsModalRef = this.bsModalService.show(PlanningAffectationComponent,{
					initialState: {
						affectation: {
							...affectation,
							...affectationToCreate
						}
					},
					class: 'modal-max',
					id: modalUuid
				});

				//Retour de l'observable de fermeture de la pop-up
				return bsModalRef.onHidden;
			})
		).subscribe(() => {
			let affectation: any;
			let affectationToClose: any;
			let newEvent: any;
			let idxAffectation: number;
			let idxAffectationToClose: number;

			//Vérification qu'une action a été effectuée
			if (bsModalRef.content?.savedData) {
				//Récupération de l'affectation après modifications
				affectation = bsModalRef.content?.savedData.affectation;

				//Récupération de l'index de l'affectation avant modifications
				idxAffectation = idAffectation ? this.listeEvents.findIndex(e => e.id == idAffectation) : -1;

				//Vérification de l'index
				if (idxAffectation > -1) {
					//Vérification de l'affectation
					if (affectation)
						//Mise à jour de l'affectation dans les évènements
						this.listeEvents[idxAffectation] = this.getEventFromAffectation(affectation,(this.typePlanning === TypePlanning.VEHICULE ? affectationToCreate?.vehicule?.idVehicule : affectationToCreate?.user?.idUser) || idEntity,moment(this.currentDate || moment()).endOf('week'));
					else
						//Suppression de l'affectation dans les évènements
						this.listeEvents.splice(idxAffectation,1);

					//Tri des ressources avant leur affichage
					this.sortResources();

					//Rafraichissement des données du planning
					this.onRefresh();
				} else if (affectation) {
					//Initialisation de l'évènement pour la nouvelle affectation
					newEvent = this.getEventFromAffectation(affectation,idEntity as number,moment(this.currentDate || moment()).endOf('week'));

					//Mise à jour de l'affectation dans les évènements
					this.listeEvents.push(newEvent);

					//Vérification de la nécessité de modifier une affectation existante
					if (bsModalRef.content?.savedData.idAffectationClosed) {
						//Récupération de l'index de l'affectation à clotûrer
						idxAffectationToClose = this.listeEvents.findIndex(e => e.id == bsModalRef.content.savedData.idAffectationClosed);

						//Vérification de la présence de l'affectation dans la liste des évènements
						if (idxAffectationToClose > -1) {
							//Récupération de l'évènement de l'affectation à clotûrer
							affectationToClose = this.listeEvents[idxAffectationToClose];

							//Suppression de l'affectation dans les évènements
							this.listeEvents.splice(idxAffectationToClose,1);

							//Modification de l'affectation clotûrée
							affectationToClose.end = newEvent.start;
							affectationToClose.extendedProps.hasDateFin = true;

							//Mise à jour de l'affectation dans les évènements
							this.listeEvents.push(affectationToClose);
						}
					}

					//Tri des ressources avant leur affichage
					this.sortResources();

					//Rafraichissement des données du planning
					this.onRefresh();
				}
			} else if (affectationToCreate) {
				//Annulation du déplacement si nécessaire
				affectationToCreate.eventInfo?.revert();
			}
		});
	}

	/**
	 * Génération d'un évènement depuis les données d'une affectation
	 */
	private getEventFromAffectation(affectation: any,idEntity: number,dateFin: moment.Moment): any {
		//Retour de l'évènement
		return {
			id: affectation.idAffectation,
			resourceId: null,
			title: this.typePlanning === TypePlanning.VEHICULE ? (affectation.typeAffectation != 'VEHICULE_IMMOBILISE' ? this.getUserTitle(affectation.user) : this.translateService.instant('vehicule.affectation.immobilisation')) : this.getVehiculeTitle(affectation.vehicule,true),
			start: affectation.dateDebut,
			end: affectation.dateFin || moment(dateFin).add(7,'days').valueOf(),
			color: this.getEventColor(affectation.typeAffectation),
			extendedProps: {
				typePlanning: this.typePlanning,
				type: affectation.typeAffectation,
				user: affectation?.user ? this.getUserTitle(affectation?.user) : null,
				vehicule: affectation?.vehicule ? this.getVehiculeTitle(affectation?.vehicule,false) : null,
				carburant: affectation?.vehicule?.carburant?.libelle,
				hasDateFin: !!affectation.dateFin,
				remarque: affectation.remarque,
				resourceOriginalId: idEntity
			}
		};
	}

	/**
	 * Récupération du libellé du véhicule
	 */
	private getVehiculeTitle(vehicule: any,withCarburant: boolean): string {
		let title: string;

		//Ajout de l'immatriculation au libellé
		title = vehicule?.reference;

		//Vérification du numéro interne
		if (vehicule?.numeroInterne)
			//Ajout du numéro interne
			title += ' - ' + vehicule?.numeroInterne;

		//Vérification du modèle ou de la marque
		if (vehicule?.marque || vehicule?.modele) {
			//Ajout de la parenthèse
			title = title + ' (';

			//Vérification de la marque
			if (vehicule?.marque)
				//Ajout de la marque
				title = title + vehicule?.marque.libelle;

			//Vérification de la marque et du modèle
			if (vehicule?.marque && vehicule?.modele)
				//Ajout d'un espace
				title = title + ' ';

			//Vérification du modèle
			if (vehicule?.modele)
				//Ajout du modèle
				title = title + vehicule?.modele.libelle;

			//Vérification du carburant
			if (withCarburant && vehicule?.carburant?.reference)
				//Ajout du carburant
				title = title + ' - ' + vehicule?.carburant?.reference;

			//Ajout de la parenthèse
			title = title + ')';
		}

		return title;
	}

	/**
	 * Récupération du libellé de l'utilisateur
	 */
	private getUserTitle(user: any): string {
		//Définition du libellé de l'utilisateur
		return `${user?.prenom} ${user.nom} (${user?.matricule})`;
	}

	/**
	 * Récupération de la couleur de l'affectation
	 */
	private getEventColor(type: string): string {
		//Vérification du type
		if (type == 'VEHICULE_FONCTION')
			//Définition de la couleur
			return this.chartService.getColorFor('nc-primary');
		else if (type == 'VEHICULE_SERVICE')
			//Définition de la couleur
			return this.chartService.getColorFor('nc-secondary');
		//Définition de la couleur
		else if (type == 'VEHICULE_IMMOBILISE')
			//Définition de la couleur
			return this.chartService.getColorFor('c-danger');
		else if (type == 'HORS_PARC' || type == 'HORS_CONTRAT')
			//Définition de la couleur
			return this.chartService.getColorFor('nc-text-primary');
		else
			//Définition de la couleur
			return this.chartService.getColorFor('nc-list-avatar');
	}

	/**
	 * Interception du rafraichissement des données
	 */
	private onRefresh() {
		//Mise à jour des ressources et des évènements
		this.calendarApi?.refetchResources();
		this.calendarApi?.refetchEvents();
	}

	/**
	 * Récupération du contenu de l'en-tête de la colonne des ressources
	 */
	private getResourceAreaHeaderContent() {
		//Vérification que le sélecteur du type de regroupement doit être initialisé
		if (!this.typeRegroupementSelectorNodes)
			//Récupération du contenu du sélecteur du type de regroupement
			this.typeRegroupementSelectorNodes = this.typeRegroupementSelectorViewRef?.rootNodes || [];

		//Retour du contenu de l'en-tête de la colonne des ressources
		return { domNodes: this.typeRegroupementSelectorNodes };
	}

	/**
	 * Interception de l'affichage d'un groupe
	 */
	private resourceGroupLabelDidMount(arg: ColCellMountArg) {
		let groupe: any;
		let expanderElement: HTMLElement;

		//Récupération du groupe
		groupe = this.listeGroupes.find(g => g.uuid == arg.groupValue);

		//Vérification du groupe
		if (groupe) {
			//Récupération de l'élément permettant de modifier l'ouverture du groupe de ressources
			expanderElement = arg.el.querySelector('.fc-datagrid-expander');

			//Ajout d'un listener du clic sur le bouton
			this.renderer.listen(expanderElement,'click',() => {
				//Vérification que l'on a ouvert un groupe vide
				if (expanderElement.querySelector('.fc-icon-minus-square') && !this.mapNumPageByGroup[groupe.clef])
					//Affichage de plus de ressources dans le groupe
					this.showMoreResources(groupe);
			});

			//Vérification que le groupe devrait être ouvert mais ne l'est plus
			if (this.mapToggleStateByGroup[groupe.clef] && expanderElement.querySelector('.fc-icon-plus-square'))
				//Réouverture du groupe
				(expanderElement.querySelector('.fc-icon-plus-square') as HTMLElement).click();

			//Ajout d'un listener du clic sur le bouton
			this.renderer.listen(expanderElement,'click',() => {
				//Mémorisation du nouvel état du groupe
				this.mapToggleStateByGroup[groupe.clef] = !this.mapToggleStateByGroup[groupe.clef];
			});

			//Vérification qu'aucun groupe n'a encore été ouvert
			if (Object.keys(this.mapNumPageByGroup).length == 1 && Object.keys(this.mapToggleStateByGroup).length == 0 && groupe.order === 0)
				//Ouverture automatique du premier groupe
				expanderElement.click();
		} else {
			//Écoute du clic sur le groupe fictif
			this.renderer.listen(arg.el,'click',() => {
				//Affichage de plus de groupes
				this.showMoreGroups();
			});
		}
	}

	/**
	 * Récupération des classes CSS à attribuer à un groupe
	 */
	private getResourceGroupLabelClassNames(arg: ColCellContentArg) {
		//Vérification qu'il s'agit du groupe fictif
		if (this.listeGroupes.findIndex(g => g.uuid == arg.groupValue) == -1) {
			//Ajout de la classe
			return 'show-more';
		}
	}

	/**
	 * Récupération du contenu de la cellule d'en-tête du groupe de ressources
	 */
	private getResourceGroupLabelContent(arg: ColCellContentArg) {
		let groupe: any;
		let html: string;

		//Récupération du groupe
		groupe = this.listeGroupes.find(g => g.uuid == arg.groupValue);

		//Vérification du groupe
		if (groupe) {
			//Définition du contenu de la cellule
			html = '<span class="pull-right">';
			html += '	<span class="label label-info">' + groupe.nbItems + '</span>';
			html += '</span>';
			html += (groupe.idGroupe || groupe.libelleParent || groupe.libelleGroupe ? this.selectedTypeRegroupement.getLabel(groupe) : this.translateService.instant('common.nonDefini'));

			//Retour du contenu de la cellule
			return { html };
		} else {
			//Recherche du groupe fictif
			groupe = this.listeResources.find(r => r.groupOrder == this.listeGroupes.length && r.isFictive);

			//Retour du libellé du groupe
			return groupe?.title || '';
		}
	}

	/**
	 * Interception de l'affichage d'une ressource
	 */
	private resourceLabelDidMount(arg: ResourceLabelMountArg) {
		//Vérification du bouton 'Afficher plus'
		if (arg.resource.extendedProps.typeFictiveResource == 'SHOW_MORE') {
			//Écoute du clic sur le lien
			this.renderer.listen(arg.el,'click',() => {
				//Affichage de plus de ressources dans le groupe
				this.showMoreResources(this.listeGroupes[arg.resource.extendedProps.groupOrder]);
			});
		} else if (this.onResourceClick) {
			//Écoute du clic sur le lien
			this.renderer.listen(arg.el,'click',() => {
				//Exécution de l'action
				this.onResourceClick(arg.resource.extendedProps);
			});
		}
	}

	/**
	 * Récupération des données à afficher dans le tooltip des affectations
	 */
	private getEventDataForTooltip(event: EventApi) {
		//Vérification du type de planning
		if (this.typePlanning === TypePlanning.VEHICULE) {
			//Retour des données
			return event.display != 'background' ? {
				affectation: {
					type: event.extendedProps.type,
					user: event.extendedProps.user,
					start: event.start,
					end: event.extendedProps.hasDateFin ? event.end : null,
					remarque: event.extendedProps.remarque
				}
			} : null;
		} else if (this.typePlanning === TypePlanning.USER) {
			//Retour des données
			return event.display != 'background' ? {
				affectation: {
					type: event.extendedProps.type,
					vehicule: event.extendedProps.vehicule,
					carburant: event.extendedProps?.carburant,
					start: event.start,
					end: event.extendedProps.hasDateFin ? event.end : null,
					remarque: event.extendedProps.remarque
				}
			} : null;
		}
	}

	/**
	 * Interception du déplacement/redimensionnement d'un événement
	 */
	private onEventDrop(arg: EventDropArg | EventResizeDoneArg) {
		//Vérification du type de planning
		if (this.typePlanning === TypePlanning.VEHICULE) {
			//Chargement du véhicule
			this.vehiculeService.loadVehicule('newResource' in arg && arg.newResource?.extendedProps.originalId || arg.event.extendedProps.resourceOriginalId).subscribe(result => {
				//Vérification du chargement du véhicule
				if (result.data?.vehicule) {
					//Affichage de l'affectation
					this.showAffectation(arg.event.extendedProps.resourceOriginalId,arg.event.id,{
						vehicule: result.data.vehicule,
						dateDebut: arg.event.start,
						dateFin: arg.event.end,
						eventInfo: arg
					});
				}
			});
		} else if (this.typePlanning === TypePlanning.USER) {
			//Chargement de l'utilisateur
			this.userService.loadUser('newResource' in arg && arg.newResource?.extendedProps.originalId || arg.event.extendedProps.resourceOriginalId).subscribe(result => {
				//Vérification du chargement de l'utilisateur
				if (result.data?.user) {
					//Affichage de l'affectation
					this.showAffectation(arg.event.extendedProps.resourceOriginalId,arg.event.id,{
						user: result.data.user,
						dateDebut: arg.event.start,
						dateFin: arg.event.end,
						eventInfo: arg
					});
				}
			});
		}
	}
}