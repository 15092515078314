import { Component } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';

@Component({
	templateUrl: './conversation-list-item.component.html'
})
export class ConversationListItemComponent extends ListViewItem<any> {
	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Interception d'une modification de l'objet
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idSession,object?.idSession && !this.data?.idSession);
	}
}