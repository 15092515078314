<div class="tile-toggle">
	<div class="tile-inner" *ngIf="data.idSession">
		<div class="pull-left lv-avatar"><icon name="forum"></icon></div>
		<div class="lv-title">
			<a [routerLink]="[]" (click)="data.isDisplayed = !data.isDisplayed" [title]="data.titre">{{ data.titre }}</a>
		</div>
	</div>
	<ul class="lv-attrs" *ngIf="data.idSession">
		<li><span translate>chatbot.session.date</span>&#160;:&#160;<span>{{ data.date | date:'short' }}</span></li>
		<li *ngIf="data.tenant"><span translate>chatbot.session.tenant</span>&#160;:&#160;<span>{{ data.tenant | display:'tenant' }}</span></li>
		<li><span translate>chatbot.session.identifiant</span>&#160;:&#160;<span>{{ data.correlationId }}</span></li>
		<li *ngIf="data.score"><span translate>article.feedback.score</span>&#160;:&#160;<span *ngFor="let idx of [1,2,3,4,5]"><icon [name]="idx > data.score ? 'star_outline' : 'star'" class="m-l-5" [ngClass]="idx <= data.score && 'c-warning'"></icon></span></li>
	</ul>
</div>
<div class="tile-detail-container" *ngIf="data.isDisplayed">
	<conversation [chatbotSession]="data" (onObjectChange)="onObjectChange($event)"></conversation>
</div>