import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ImportService } from './import.service';
import { TypeDroit } from 'src/app/domain/security/right';
import { LayoutService } from 'src/app/share/layout/layout.service';

@Component({
	templateUrl: './import-list-item.component.html'
})
export class ImportListItemComponent extends ListViewItem<any> {
	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private importService: ImportService,private confirmService: ConfirmService,private translateService: TranslateService,private toastrService: ToastrService,private historyService: HistoryService,private layoutService: LayoutService,public rightService: RightService) {
		//Héritage
		super();
	}

	/**
	 * Interception d'une modification de l'objet
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idExecution,object?.idExecution && !this.data?.idExecution);
	}

	/**
	 * Suppression de l'objet
	 */
	deleteImport() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de l'éxecution
					this.importService.deleteImport(this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression de l'éxecution
								this.onObjectChange(null);
							} else {
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
							}
						}
					});
				}
			}
		});
	}

	/**
	 * Relance de l'import
	 */
	retryImport(connecteurExecution: any) {
		//Relance de l'import
		this.importService.retryImport(connecteurExecution).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Vérification du statut
					if (connecteurExecution.success = result.data?.import?.success)
						//Message d'information
						this.toastrService.success(this.translateService.instant('connecteur.actions.execution.success'));
					else
						//Message d'information
						this.toastrService.error(this.translateService.instant('connecteur.actions.execution.error'));
				} else
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('connecteur.actions.execution.error'));
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory(className: string,idObject: number,idTenant: number) {
		//Affichage de l'historique des modifications
		this.historyService.showHistory(className,idObject,null,this.rightService.isRoot() || this.rightService.isRevendeur() ? idTenant : null);
	}

	/**
	 * Téléchargement des journaux d'exécution
	 */
	downloadLogs() {
		//Téléchargement des journaux d'exécution
		this.importService.generateLogs(this.data.idExecution);
	}

	/**
	 * Accès au connecteur
	 */
	goToConnecteur() {
		//Navigation vers le connecteur
		this.layoutService.goToByState('listeConnecteurs-connecteur',{
			routeParams: {
				idConnecteur: this.data.connecteur.idConnecteur
			},
			withGoBack: true
		});
	}
}