import { Injectable } from '@angular/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { first,map } from 'rxjs/operators';
import { v4 } from 'uuid';

import { ConfirmComponent } from './confirm.component';

/**
 * Service de gestion de la pop-up de confirmation d'une action
 */
@Injectable()
export class ConfirmService {
	/** Liste des popups ouvertes **/
	private listeModals: Array<string> = [];

	/**
	 * Constructeur
	 */
	constructor(private bsModalService: BsModalService) { }

	/**
	 * Ouverture d'une pop-up de confirmation
	 */
	showConfirm(message: string,options?: { withTyping?: boolean,withTitle?: boolean,confirmationTerm?: string,alerteInfo?: string,isCancelDisabled?: boolean,buttonType?: 'CONFIRM_CANCEL' | 'YES_NO',actionColor?: 'primary' | 'accent',listeActions?: Array<{ type: string,libelle?: string,buttonType?: 'STROKE' | 'RAISED' }> }): Observable<any> {
		let bsModalRef: BsModalRef<ConfirmComponent>;
		let uniqueIdentifier: string;
		let isLastModal: () => boolean;

		//Méthode de vérification de la dernière popup ouverte
		isLastModal = () => this.listeModals[this.listeModals.length - 1] === uniqueIdentifier;

		//Ajout d'un identifiant unique réprésentant la popup
		this.listeModals.push(uniqueIdentifier = v4().toString());

		//Ouverture de la pop-up de confirmation
		bsModalRef = this.bsModalService.show(ConfirmComponent,{
			initialState: {
				message,
				options,
				isLastModal
			},
			ignoreBackdropClick: !!options?.isCancelDisabled
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			first(),
			map(() => {
				//Suppression de la référence unique
				this.listeModals.splice(this.listeModals.indexOf(uniqueIdentifier),1);

				//Retour du résulat
				return options?.listeActions && bsModalRef.content.choice ? bsModalRef.content.choice : bsModalRef.content.isConfirmed;
			})
		);
	}
}