<div class="pull-left lv-avatar" [nSelector]="{ liste,item: data }">{{ data?.code?.substring(0,3)?.toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="['/Tenant',data.idTenant,0]">{{ data | display:'tenant' }}</a>
	</div>
	<ul class="lv-attrs" *ngIf="data.idTenant">
		<li>
			<span translate>tenant.offre</span>&#160;:
			<span *ngIf="data.listeOffres?.length">
				<span *ngFor="let offre of data.listeOffres; let idxOffre = index;">{{ (idxOffre ? ', ' : '') + offre.libelle }}</span>
			</span>
			<span *ngIf="!data.listeOffres?.length" translate>common.aucune</span>
		</li>
		<li><span translate>common.actif</span>&#160;:&#160;<span [translate]="'common.'+(data.actif ? 'oui' : 'non')"></span></li>
		<li><span translate>tenant.revendeur</span>&#160;:&#160;<span>{{ data.revendeur.libelle }}</span></li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="data?.idTenant && !liste.nbSelectedItems">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li *ngIf="hasNotifications()"><a [routerLink]="[]" (click)="sendNotification()" translate>actions.notifier</a></li>
			<li><a [routerLink]="[]" (click)="showMailHistory()"><span translate>actions.mail.historique.consulter</span></a></li>
			<li *ngIf="(null | right:'suppression') && (!data.tenantDescription?.deleted || !data.tenantDescription?.closed) && rightService.isRoot() && !hasRestrictionClient()"><a [routerLink]="[]" (click)="deleteTenant()"><span translate>actions.supprimer</span></a></li>
			<li *ngIf="canConnectAs() && !data?.tenantDescription?.deleted"><a [routerLink]="[]" (click)="loginAsAdminTenant()"><span translate>actions.seConnecter</span></a></li>
			<li><a [routerLink]="[]" (click)="showHistory()"><span translate>actions.historique.consulter</span></a></li>
			<li *ngIf="(null | right:'suppression') && !data.tenantDescription?.deleted && !data.tenantDescription?.closed && rightService.isRoot() && !hasRestrictionClient()"><a [routerLink]="[]" (click)="closeTenant()"><span translate>actions.cloturer</span></a></li>
		</ul>
	</div>
</div>