import { Component,OnInit } from '@angular/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { TypeFlux } from 'src/app/domain/connecteur/type-flux';
import { environment } from 'src/environments/environment';
import { ImportFournisseurListItemComponent } from './import-fournisseur-list-item.component';

@Component({
	templateUrl: './import-fournisseur-list.component.html'
})
export class ImportFournisseurListComponent implements OnInit {
	/** Liste des fournisseurs **/
	liste: ListView<any,ImportFournisseurListItemComponent>;

	/** Type de flux **/
	typeFlux: TypeFlux;

	/** Fournisseur sélectionné **/
	selectedFournisseur: any;

	/** Sélection d'un fournisseur **/
	onFournisseurSelected: (fournisseur: any) => void;

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ImportFournisseurListItemComponent>({
			uri: `${environment.baseUrl}/controller/Connecteur/filtreConnecteursGroupByFournisseur/${this.typeFlux}`,
			component: ImportFournisseurListItemComponent,
			isLocal: true,
			nbObjetsParPage: 9,
			isContentHidden: true,
			extraOptions: {
				onFournisseurSelected: this.onFournisseurSelected
			},
			listeFilters: [{
				clef: 'type.fournisseurInterface.fournisseur',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'fournisseur'
				},
				isDefault: true
			}]
		});
	}
}