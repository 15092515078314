import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { pick } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { MessagingObservables } from 'src/app/domain/messaging/messaging-observables';
import { User } from 'src/app/domain/user/user';
import { MessagingService } from 'src/app/share/components/messaging/messaging.service';
import { LoggedUserService } from 'src/app/share/login/logged-user.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ConnecteurService } from './connecteur.service';

@Component({
	selector: 'connecteur-edit',
	templateUrl: './connecteur-edit.component.html'
})
export class ConnecteurEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() connecteur: any;

	/** Résumé **/
	@Input() resume: any;

	/** Suppression de l'objet **/
	@Input() deleteConnecteur: () => void;

	/** Liste des types d'environnement **/
	public listeTypesEnvironnement: Array<{ code: string,libelle: string}>;

	/** Liste des types d'exécution **/
	public listeTypesExecution: Array<{ code: string,libelle: string}>;

	/** Utilisateur connecté **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,public rightService: RightService,private translateService: TranslateService,private connecteurService: ConnecteurService,public loggedUserService: LoggedUserService,private messagingService: MessagingService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Initialisation de la liste des types d'environnement
		this.listeTypesEnvironnement = this.connecteurService.getListeTypesEnvironnement();

		//Initialisation de la liste des types d'exécution
		this.listeTypesExecution = this.connecteurService.getListeTypesExecution();

		//Récupération de l'utilisateur connecté
		this.user = this.loggedUserService.getUser();
	}

	/**
	 * Affichage des paramètres
	 */
	public showParams() {
		//Affichage des paramètres
		this.connecteurService.showParams(this.connecteur).subscribe({
			next: listeParams => {
				//Mise à jour des paramètres du connecteur
				this.connecteur.listeParams = listeParams;
			}
		});
	}

	/**
	 * Affichage des destinataires
	 */
	showDestinataires() {
		//Affichage de la liste des destinataires
		this.connecteurService.showDestinataires(this.connecteur).subscribe({
			next: listeEmails => {
				//Mise à jour des emails
				this.connecteur.listeEmails = listeEmails;
			}
		})
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveConnecteur() {
		let checkResult: (result: Result,isWebSocket: boolean) => void;
		let messaging$: MessagingObservables;

		//Vérification du résultat
		checkResult = (result: Result,isWebSocket: boolean) => {
			let listeDoublons = new Array<string>();

			//Vérification du code d'erreur
			if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
				//Vérification du mode
				if (!isWebSocket) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Mise à jour de l'objet
					Object.assign(this.connecteur,result.data?.connecteur);

					//Fermeture du formulaire
					this.close(this.connecteur);
				}
			} else {
				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
					//Ajout des libellés
					listeDoublons.push(this.translateService.instant('actions.doublon.connecteur'));
					listeDoublons.push(this.translateService.instant('actions.doublon.version'));

					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
						field: listeDoublons.join(', ')
					}));
				} else {
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
				}
			}
		}

		//Vérification du type de connecteur
		if (this.connecteur.type.typeConnecteur == 'INTERFACE' && this.connecteur.typeExecution != 'MIDDLEWARE') {
			//Démarrage de l'action par websocket
			messaging$ = this.messagingService.init({
				method: 'PUT',
				entryPoint: `controller/Connecteur/saveConnecteur`,
				params: this.connecteur,
				progressConfig: {
					libelle: this.translateService.instant('connecteur.creationAbonnement'),
					icone: 'autorenew'
				}
			}).onFinish({
				next: (message: any) => {
					//Vérification des compteurs
					if (message.nbProcessed) {
						//Message d'information
						this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

						//Rechargement du connecteur
						this.connecteurService.loadConnecteur(this.connecteur.idConnecteur).pipe(first()).subscribe({
							next: (result: Result) => {
								//Mise à jour de l'objet
								Object.assign(this.connecteur,result.data?.connecteur);

								//Fermeture du formulaire
								this.close(this.connecteur);
							},
							complete: () => {
								//Fermeture des souscriptions
								messaging$.unsubscribe();
							}
						});
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));

						//Fermeture des souscriptions
						messaging$.unsubscribe();
					}
				}
			}).onError({
				next: () => {
					//Fermeture des souscriptions
					messaging$.unsubscribe();
				}
			}).onResult({
				next: (result: Result) => checkResult(result,true)
			});
		} else {
			//Enregistrement direct de l'objet
			this.connecteurService.saveConnecteur(this.connecteur).subscribe({
				next: (result: Result) => checkResult(result,false)
			});
		}
	}

	/**
	 * Interception d'un changement de type de connecteur
	 */
	onConnecteurTypeChange() {
		//Vidage des données du connecteur
		this.connecteur = pick(this.connecteur,['idConnecteur','tenant','dateLastExecution','objectVersion','type']);

		//Remise à zéro du type d'environnement
		this.connecteur.typeEnvironnement = 'DEFAULT';

		//Vérification de la liste des destinataires et création si nécessaire
		this.connecteur.listeEmails = this.connecteur.listeEmails || [];

		//Vérification du nombre d'éléments présents dans la liste des destinataires
		if (this.connecteur.listeEmails.length == 0 && this.connecteur.type)
			//Ajout de l'adresse e-mail de l'administrateur en automatique
			this.connecteur.listeEmails.push(this.connecteur.type.adminEmail);

		//Vérification du type d'exécution
		if (this.connecteur.type?.fournisseurInterface?.typeExecution == 'MIDDLEWARE_STABLE')
			//Mise à jour du type d'exécution du connecteur
			this.connecteur.typeExecution = 'MIDDLEWARE';
	}
}