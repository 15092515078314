import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { TypeDroit } from 'src/app/domain/security/right';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserService {
	/** Liste des types de civilité **/
	private readonly listeTypesCivilite: Array<string> = ['MONSIEUR','MADAME'];

	/** Liste des types de responsable **/
	private readonly listeTypesUserResponsable: Array<{ code: string,droit: TypeDroit }> = [{
		code: 'VALIDEUR',
		droit: TypeDroit.ADMIN_ANALYTIQUE_SERVICE
	},{
		code: 'RESPONSABLE',
		droit: TypeDroit.ADMIN_UTILISATEUR
	},{
		code: 'RESPONSABLE_POOL',
		droit: TypeDroit.VEHICULE_POOL
	}];

	/** Liste des type de valideurs **/
	private readonly listeTypesValideur: Array<string> = ['N1','N2','N3','N4']

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService,private rightService: RightService) { }

	/**
	 * Récupération de la liste des types de civilité
	 */
	public getListeTypesCivilite(): Array<string> {
		//Retour de la liste des types de civilité
		return Object.assign([],this.listeTypesCivilite);
	}

	/**
	 * Chargement du résumé de l'utilisateur
	 */
	public loadResume(idUser: number): Observable<Result> {
		//Chargement du résumé de l'utilisateur
		return this.http.post<Result>(`${environment.baseUrl}/controller/User/loadResume/${idUser}`,null);
	}

	/**
	 * Enregistrement d'un utilisateur
	 */
	public saveUser(user: any): Observable<Result> {
		//Enregistrement d'un utilisateur
		return this.http.put<Result>(`${environment.baseUrl}/controller/User/saveUser`,user);
	}

	/**
	 * Suppression d'un utilisateur
	 */
	public deleteUser(idUser: number): Observable<Result> {
		//Suppression d'un utilisateur
		return this.http.delete<Result>(`${environment.baseUrl}/controller/User/deleteUser/${idUser}`);
	}

	/**
	 * Chargement d'un utilisateur
	 */
	public loadUser(idUser: number): Observable<Result> {
		//Chargement d'un utilisateur
		return this.http.post<Result>(`${environment.baseUrl}/controller/User/loadUser/${idUser}`,null);
	}

	/**
	 * Chargement de la première imputation
	 */
	public getFirstImputation(user: any): Observable<Result> {
		//Chargement de la première imputation
		return this.http.post<Result>(`${environment.baseUrl}/controller/User/getFirstImputation/${user.idUser}`,null);
	}

	/**
	 * Enregistrement de l'imputation
	 */
	public saveImputation(imputation: any): Observable<Result> {
		//Enregistrement de l'imputation
		return this.http.put<Result>(`${environment.baseUrl}/controller/User/saveImputation`,imputation);
	}

	/**
	 * Suppression de l'imputation
	 */
	public deleteImputation(imputation: any): Observable<Result> {
		//Suppression de l'imputation
		return this.http.delete<Result>(`${environment.baseUrl}/controller/User/deleteImputation/${imputation.idImputation}`);
	}

	/**
	 * Enregistrement du service ou de l'utilisateur à valider
	 */
	public saveUserResponsable(userResponsable: any,isForceUpdate: boolean = false): Observable<Result> {
		//Enregistrement du service ou de l'utilisateur à valider
		return this.http.put<Result>(`${environment.baseUrl}/controller/User/saveUserResponsable/${isForceUpdate}`,userResponsable);
	}

	/**
	 * Suppression du service ou de l'utilisateur à valider
	 */
	public deleteUserResponsable(userResponsable: any): Observable<Result> {
		//Suppression du service ou de l'utilisateur à valider
		return this.http.delete<Result>(`${environment.baseUrl}/controller/User/removeUserResponsable/${userResponsable.idObject}/${userResponsable.type}/${userResponsable.typeValideur}`);
	}

	/**
	 * Enregistrement du profil pour un utilisateur
	 */
	public saveLienProfil(lienProfil: any): Observable<Result> {
		//Enregistrement du profil pour un utilisateur
		return this.http.put<Result>(`${environment.baseUrl}/controller/User/saveProfil`,lienProfil);
	}

	/**
	 * Suppression du profil pour un utilisateur
	 */
	public deleteLienProfil(lienProfil: any): Observable<Result> {
		//Suppression du profil pour un utilisateur
		return this.http.delete<Result>(`${environment.baseUrl}/controller/User/deleteProfil/${lienProfil?.user?.idUser}/${lienProfil?.idLien}`);
	}

	/**
	 * Activation/désactivation du profil pour un utilisateur
	 */
	public toggleLienProfil(lienProfil: any): Observable<Result> {
		//Activation/désactivation du profil pour un utilisateur
		return this.http.post<Result>(`${environment.baseUrl}/controller/User/toggleUserProfil/${lienProfil.idLien}`,null);
	}

	/**
	 * Transmission des éléments de connexion
	 */
	public sendAccount(user: any): Observable<Result> {
		//Transmission des éléments de connexion
		return this.http.post<Result>(`${environment.baseUrl}/controller/User/sendAccount/${user?.idUser}`,null);
	}

	/**
	 * Récupération de la liste des types de périmètres RH
	 */
	public getListeTypesUserResponsable(): Array<{ code: string,libelle: string }> {
		//Liste des types de périmètres RH
		return this.listeTypesUserResponsable.map(item => ({
			...item,
			libelle: this.translateService.instant(`user.userResponsable.type.${item.code}.item`)
		})).filter(item => this.rightService.hasRight(item.droit,'creation'));
	}

	/**
	 * Récupération de la liste des type de valideurs
	 */
	public getListeTypesValideur(type: 'VALIDEUR' | 'RESPONSABLE'): Array<{ code: string,libelle: string }> {
		//Liste des types de valideurs
		return this.listeTypesValideur.map(code => ({
			code,
			libelle: this.translateService.instant(`user.userResponsable.type.${type}.${code}`) || null
		})).filter(t => type != 'VALIDEUR' || !['N3','N4'].includes(t.code));
	}

	/**
	 * Lecture d'un type d'article pour l'utilisateur
	 */
	public readTypeArticle(user: any,typeArticle: 'CGU' | 'ONBOARDING' | 'STANDARD' | 'NOUVEAUTES' | 'ALERTE'): Observable<Result> {
		//Lecture d'un type d'article pour l'utilisateur
		return this.http.post<Result>(`${environment.baseUrl}/controller/User/readTypeArticle/${typeArticle}/${user.idUser}`,null);
	}
}