import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';

@Injectable()
export class ConversationService {
	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient) {}

	/**
	 * Chargement d'une conversation
	 */
	public loadConversation(idConversation: number): Observable<Result> {
		//Chargement d'une conversation
		return this.http.post<Result>(`${environment.baseUrl}/controller/Chatbot/loadConversation/${idConversation}`,null);
	}
}