import { Component,EventEmitter,Input,OnInit,Output } from "@angular/core";
import { cloneDeep } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';

import { PluralTranslatePipe } from 'src/app/share/pipe/plural-translate/plural-translate.pipe';
import { ConversationService } from "./conversation.service";
import { ArticleService as SharedArticleService } from 'src/app/share/components/article/article.service';
import { TypeCodeErreur } from "src/app/domain/common/http/result";

@Component({
	selector: 'conversation',
	templateUrl: './conversation.component.html'
})
export class ConversationComponent implements OnInit {
	/** Elément courant **/
	@Input() chatbotSession: any;

	/** Gestion d'une modification de l'élément **/
	@Output() onObjectChange = new EventEmitter<any>();

	/** Liste des échanges avec le chatbot **/
	listChatbotExchanges: Array<any>;

	/**
	 * Constructeur
	 */
	constructor(private conversationService: ConversationService,private translateService: TranslateService,private pluralPipe: PluralTranslatePipe,private sharedArticleService: SharedArticleService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération des échanges
		this.conversationService.loadConversation(this.chatbotSession.idSession).subscribe({
			next: result => {
				//Vérification du chargement
				if (result.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Initialisation de la liste
					this.listChatbotExchanges = [];

					//Vérification du résultat
					if (result.data.listeExchanges?.length)
						//Parcours des échanges
						result.data.listeExchanges.forEach((exchange: any) => {
							let responseExchange;
							let articleExchange;

							//Vérification du type de l'échange
							if (exchange.type == 'USER') {
								//Ajout de l'échange
								this.listChatbotExchanges.push(exchange);
							} else {
								//Initialisation de l'échange de réponse
								responseExchange = exchange;

								//Vérification de la présence d'articles
								if (responseExchange.action?.listeArticles?.length) {
									//Duplication de l'échange
									articleExchange = cloneDeep(exchange);

									//Suppression de l'action
									delete exchange.action;

									//Ajout de l'échange
									this.listChatbotExchanges.push(exchange);

									//Modification du contenu
									articleExchange.contenu = this.translateService.instant(this.pluralPipe.transform('chatbot.response.FAQ.result',articleExchange.action.listeArticles.length));

									//Nombre de liens à afficher
									articleExchange.limitLinks = 5;

									//Définition des liens
									articleExchange.listeLinks = articleExchange.action.listeArticles.map(article => ({
										text: article.titre,
										onClick: () => {
											//Ouverture de l'article
											this.sharedArticleService.consultArticle(article);
										}
									}));

									//Ajout de l'échange
									this.listChatbotExchanges.push(articleExchange);
								} else
									//Ajout de l'échange
									this.listChatbotExchanges.push(exchange);
							}
						});
				} else
					//Pas de liste d'échanges
					this.listChatbotExchanges = [];
			}
		});
	}

	/**
	 * Fermeture de l'écran
	 */
	close() {
		//Fermeture de l'écran
		this.onObjectChange.emit({
			isDisplayed: false
		});
	}
}