import { Component,Input,OnInit } from '@angular/core';

import { ListView } from 'src/app/domain/common/list-view';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TenantAutorisationListItemComponent } from './tenant-autorisation-list-item.component';

@Component({
	selector: 'tenant-autorisation-list',
	templateUrl: './tenant-autorisation-list.component.html'
})
export class TenantAutorisationListComponent extends PageContentItem implements OnInit {
	/** Tenant **/
	@Input() tenant: any;

	/** Fonction d'édition **/
	@Input() addAutorisation: () => void;

	/** Résumé **/
	@Input() resume: { nbAutorisations: number };

	/** Données **/
	liste: ListView<any,TenantAutorisationListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor() {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,TenantAutorisationListItemComponent>({
			uri: () => `/controller/Tenant/filtreAutorisations/TENANT/${this.tenant?.idTenant}`,
			component: TenantAutorisationListItemComponent,
			isLocal: true,
			extraOptions: {
				addAutorisation: this.addAutorisation,
				resume: this.resume
			},
			defaultOrder: '-dateFin',
			getKeyFieldName: () => 'idAutorisation'
		});
	}

	/**
	 * Interception de la suppression d'un élément
	 */
	onItemRemove(item: any): void {
		//Vérification de l'élément
		if (item.idAutorisation > 0)
			//Mise à jour des compteurs
			this.resume.nbAutorisations--;
	}
}