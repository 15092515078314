import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ReponseDemandeCotationService } from './reponse-demande-cotation.service';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';

@Component({
	selector: 'reponse-cotation',
	templateUrl: './reponse-cotation.component.html'
})
export class ReponseCotationComponent extends PageContentItem implements OnInit {
	/** Réponse à la cotation **/
	@Input() reponseCotation: any;

	/** Source **/
	@Input() source: string;

	/** Indicateur d'ouverture depuis une demande de cotation **/
	@Input() isFromDemandeCotation: boolean;

	/** Indicateur de lecture seule **/
	@Input() isReadOnly: boolean;

	/**
	 * Constructeur
	 */
	constructor(private reponseDemandeCotationService: ReponseDemandeCotationService,private toastrService: ToastrService,private translateService: TranslateService,private confirmService: ConfirmService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Fermeture du rappel
		this.reponseCotation.isCotationToggled = false;

		//Ouverture du bloc de réponse
		this.reponseCotation.isReponseToggled = false;
	}

	/**
	 * Enregistrement de la réponse
	 */
	saveReponseCotation() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('reponseDemandeCotation.reponseCotation.reponse.actions.validation'),{
			listeActions: [{
				type: 'SAVE',
				buttonType: 'STROKE',
				libelle: 'actions.enregistrer'
			},{
				type: 'SEND',
				libelle: 'reponseDemandeCotation.reponseCotation.reponse.actions.enregistrerEtEnvoyer'
			}]
		}).subscribe(result => {
			//Modification du statut en fonction du choix du fournisseur
			this.reponseCotation.statut = result == 'SEND' ? 'TRANSMISE' : 'A_TRANSMETTRE';

			//Enregistrement de la réponse
			this.reponseDemandeCotationService.saveReponseCotation(this.reponseCotation).subscribe(result => {
				//Vérification du code d'erreur
				if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
					//Message d'information
					this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

					//Fermeture de la fenêtre
					this.close({
						reponseCotation: result.data.reponseCotation,
						reponseDemandeCotation: result.data.reponseDemandeCotation,
						nbCotationsATransmettre: result.data.nbCotationsATransmettre,
						nbCotationsTransmises: result.data.nbCotationsTransmises
					});
				} else
					//Message d'erreur
					this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
			});
		});
	}

	/**
	 * Rejet de la réponse à la cotation
	 */
	doDeclineCotation(reponseCotation: any = this.reponseCotation) {
		//Message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('reponseDemandeCotation.actions.decliner.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Modification du statut en 'DECLINEE'
					reponseCotation.statut = 'DECLINEE';

					//Sauvegarde de la réponse à la cotation
					this.reponseDemandeCotationService.saveReponseCotation(reponseCotation).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Fermeture de l'édition
								this.close({
									reponseCotation: result.data.reponseCotation,
									reponseDemandeCotation: result.data.reponseDemandeCotation,
									nbCotationsATransmettre: result.data.nbCotationsATransmettre,
									nbCotationsTransmises: result.data.nbCotationsTransmises
								});
							} else
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
						}
					});
				} else
					//Réinitialisation de la question
					reponseCotation.answered = undefined;
			}
		});
	}
}