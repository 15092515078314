import { AfterViewInit,Component,ElementRef,EventEmitter,forwardRef,Input,OnDestroy,OnInit,Output } from '@angular/core';
import { ControlValueAccessor,NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable,Observer } from 'rxjs';
import { debounceTime,finalize,map,switchMap,tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { RightService } from 'src/app/share/pipe/right/right.service';
import { GLOBALS } from 'src/app/utils/globals';
import { AdresseService } from './adresse.service';

@Component({
	selector: 'adresse',
	templateUrl: './adresse.component.html',
	exportAs: 'adresse',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => AdresseComponent),
		multi: true
	}]
})
export class AdresseComponent implements OnInit,OnDestroy,ControlValueAccessor,AfterViewInit {
	/** Mode de recherche **/
	@Input() searchMode: 'VILLE' | 'ADRESSE' | 'FULL' = 'ADRESSE';

	/** Récupération des coordonnées WGS84 de l'adresse **/
	@Input() withCoords: boolean = false;

	/** Etat du composant **/
	@Input() disabled?: boolean;

	/** Sélection d'un élément **/
	@Output() onSelect: EventEmitter<any> = new EventEmitter<any>();

	/** Placeholder **/
	@Input() placeholder: string = '';

	/** Données recherchée **/
	searchedValue: string;

	/** Suggestions **/
	suggestions$?: Observable<Array<any>>;

	/** Indicateur de chargement **/
	isLoading: boolean = false;

	/** Interception d'un changement **/
	onChange: (object: any) => void;

	/** Interception d'un appui **/
	onTouch: (object: any) => void;

	/** Indicateur d'initialisation **/
	isInit: boolean = false;

	/** Observateur de changements sur le formulaire **/
	private fieldsetMutationObserver: MutationObserver;

	/** Adresse courante **/
	_value: any;

	/**
	 * Constructeur
	 */
	constructor(private elementRef: ElementRef,private adresseService: AdresseService,private translateService: TranslateService,private rightService: RightService,private toastrService: ToastrService) { }

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		let fieldset;

		//Définition de la liste des suggestions
		this.suggestions$ = new Observable((observer: Observer<string | undefined>) => observer.next(this.searchedValue)).pipe(
			debounceTime(200),
			tap(() => {
				//Indicateur de chargement
				this.isLoading = true;
			}),
			switchMap(query => {
				//Vérification de la recherche
				if (query?.length > 0) {
					//Réalisation de la recherche
					return this.adresseService.searchLocation(this.searchedValue,this.searchMode,this.withCoords).pipe(
						map((listeAdresses: Array<any>) => {
							//Vérification de la recherche
							if (!listeAdresses)
								//Initialisation de la liste
								listeAdresses = [];

							//Vérification du droit de création
							if (this.rightService.hasRight(null,'creation'))
								//Ajout de la possibilité de créer une adresse manuellement
								listeAdresses.push({ label: this.translateService.instant('adresse.addAdresseManuellement'),typeSaisie: 'MANUEL' });

							//Retour des résultats
							return listeAdresses;
						}),
						finalize(() => {
							//Fin du chargement
							this.isLoading = false;
						})
					)
				} else
					//Aucun résultat
					return null;
			})
		);

		//Recherche du formulaire le plus proche
		fieldset = this.elementRef.nativeElement.closest('fieldset');

		//Vérification de la présence d'un formulaire
		if (fieldset) {
			//Lecture du champ de désactivation du formulaire
			this.disabled = this.disabled || GLOBALS.$(fieldset).is(':disabled');

			//Création de l'observateur d'évènements sur le formulaire
			this.fieldsetMutationObserver = new MutationObserver(() => {
				//Lecture du champ de désactivation du formulaire
				this.disabled = this.disabled || GLOBALS.$(fieldset).is(':disabled');
			});

			//Ecoute des changements
			this.fieldsetMutationObserver.observe(fieldset,{
				attributes: true,
				attributeFilter: ['disabled']
			});
		}
	}

	/**
	 * Destruction du composant
	 */
	ngOnDestroy(): void {
		//Arrêt de l'observation des changements
		this.fieldsetMutationObserver?.disconnect();
	}

	/**
	 * Interception de l'initialisation de la vue
	 */
	ngAfterViewInit() {
		//Mise en cycle
		setTimeout(() => {
			//Initialisation effectuée
			this.isInit = true;
		});
	}

	/**
	 * Sélection d'une suggestion
	 */
	onSuggestionSelect(match?: TypeaheadMatch) {
		let adresse: any;

		//Définition de l'adresse
		adresse = match?.item || null;

		//Vérification de l'édition
		if (adresse?.typeSaisie == 'MANUEL') {
			//Réinitialisation de la recherche
			this.searchedValue = null;

			//Suppression de l'adresse
			adresse = null;

			//Affichage de la pop-up de création
			this.showCreation(this.searchMode,true,{ typeSaisie: 'MANUEL' });
		} else if (adresse?.reference && adresse?.loadNeeded) {
			//Chargement de l'adresse
			this.adresseService.findLocationDetail(adresse.reference,this.searchMode,this.withCoords).subscribe({
				next: (result: any) => {
					//Vérification de la présence d'un résultat
					if (result && (this.searchMode != 'ADRESSE' || result.rue?.length && result.ville?.length && result.codePostal?.length && result.codePays?.length)) {
						//Définition du résultat
						this.value = result;
					} else {
						//Affichage d'un message d'erreur
						this.toastrService?.warning(this.translateService.instant('adresse.error.incomplete'));

						//Réinitialisation de la recherche
						this.searchedValue = null;

						//Affichage de la pop-up de création
						this.showCreation(this.searchMode,true,{ ...result,typeSaisie: 'MANUEL' });
					}
				}
			});
		} else
			//Définition de la valeur
			this.value = adresse;
	}

	/**
	 * Vérification du changement de valeur de la saisie
	 */
	onBlur($event) {
		//Vérification de la valeur saisie
		if (!$event.target.value)
			//Réinitialisation du modèle
			this.onSuggestionSelect(null);
	}

	/**
	 * Affichage de la création d'un élément
	 */
	showCreation(searchMode: 'VILLE' | 'ADRESSE' | 'FULL',isEdition: boolean = true,adresse?: any) {
		//Affichage de la popup de création d'un nouvel élément
		this.adresseService.showCreation(searchMode,isEdition && adresse?.typeSaisie == 'MANUEL',adresse).subscribe((adresse: any) => {
			//Mise à jour de l'option
			this.value = adresse;

			//Mise à jour de la suggestion
			this.searchedValue = adresse.label;
		});
	}

	/**
	 * Suppression de l'adresse
	 */
	public removeAdresse() {
		//Réinitialisation de la valeur
		this.onSuggestionSelect(null);

		//Réinitialisation de la recherche
		this.searchedValue = null;
	}

	/**
	 * Remise à zéro des données
	 */
	public reset() {
		//Réinitialisation du modèle
		this.onSuggestionSelect(null);
	}

	/**
	 * Mise à jour de la valeur depuis l'extérieur du composant
	 */
	writeValue(value: any) {
		//Mise à jour de la valeur
		this.value = value;

		//Mise à jour de la suggestion
		this.searchedValue = value?.label;
	}

	/**
	 * Enregistrement d'un changement
	 */
	registerOnChange(fn: (object: any) => void) {
		//Définition de l'intercepteur
		this.onChange = fn;
	}

	/**
	 * Enregistrement d'un appui
	 */
	registerOnTouched(fn: (object: any) => void) {
		//Définition de l'intercepteur
		this.onTouch = fn;
	}

	/**
	 * Définition de la valeur
	 */
	set value(value: any) {
		//Vérification de la valeur
		if (value !== undefined && value !== this.value) {
			//Déclenchement de l'interception du changement
			this.isInit && this.onChange?.(value);

			//Déclenchement de l'interception de l'appui
			this.isInit && this.onTouch?.(value);

			//Enregistrement de la valeur
			this._value = value;
		}
	}
}