import { Component,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { TypeFrequence,TypeJour,TypeMois } from 'src/app/domain/scheduler/scheduler';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { SchedulerService } from './scheduler.service';

@Component({
	templateUrl: './scheduler.component.html'
})
export class SchedulerComponent implements OnInit {
	/** Scheduler courant **/
	scheduler: any;

	/** Scheduler modifié **/
	selectedScheduler: any = undefined;

	/** Indicateur de modification **/
	isModification: boolean = undefined;

	/** Options d'ordonnancement **/
	options?: {
		listeTypesFrequence?: Array<TypeFrequence>
	}

	/** Liste des fréquences **/
	listeFrequences: Array<TypeFrequence>;

	/** Types des fréquences **/
	TypeFrequence: typeof TypeFrequence = TypeFrequence;

	/** Liste des jours **/
	listeJours: Array<{ code: TypeJour,isSelected: boolean }>;

	/** Types des jours **/
	TypeJour: typeof TypeJour = TypeJour;

	/** Liste des mois **/
	listeMois: Array<TypeMois>;

	/** Types des jours **/
	TypeMois: typeof TypeMois = TypeMois;

	/**
	 * Constructeur
	 */
	constructor(private schedulerService: SchedulerService,public bsModalRef: BsModalRef<SchedulerComponent>,private rightService: RightService) {}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de l'indicateur de modification (si nécessaire)
		this.isModification = this.isModification === undefined && this.rightService.hasRight(null,'creation') || this.isModification;

		//Récupération de la liste des fréquences
		this.listeFrequences = this.schedulerService.getListeFrequences();

		//Activation des types de fréquence autorisés
		this.options?.listeTypesFrequence?.length && (this.listeFrequences = this.listeFrequences.filter(type => this.options.listeTypesFrequence.includes(type)));

		//Récupération de la liste des mois
		this.listeMois = this.schedulerService.getListeMois();

		//Récupération de la liste des jours
		this.listeJours = this.schedulerService.getListeJours().map((j,idxJour) => ({
			code: j,
			isSelected: this.scheduler?.listeJours?.includes(idxJour)
		}));
	}

	/**
	 * Vérification de la validité de la liste des jours
	 */
	isListeJoursValid(): boolean {
		//Vérification du type
		if (this.scheduler?.type == TypeFrequence.HEBDOMADAIRE)
			//Vérification de la présence de jours sélectionnés
			return this.listeJours.filter(j => j.isSelected)?.length > 0;
		else
			//Aucun jour sélectionné
			return true;
	}

	/**
	 * Récupération du nombre de jours maximum par mois
	 */
	getMaxNumJour(): number {
		//Vérification du mois fourni
		switch (this.scheduler.mois) {
		case TypeMois.FEBRUARY:
			//Retour du nombre de jours dans le mois
			return 29;
		case TypeMois.APRIL:
		case TypeMois.JUNE:
		case TypeMois.SEPTEMBER:
		case TypeMois.NOVEMBER:
			//Retour du nombre de jours dans le mois
			return 30;
		default:
			//Retour du nombre de jours dans le mois (par défaut)
			return 31;
		}
	}

	/**
	 * Enregistrement du scheduler
	 */
	saveScheduler() {
		//Vérification du type
		if (this.scheduler.type != TypeFrequence.NON_DEFINI) {
			//Définition du scheduler sélectionné
			this.selectedScheduler = this.scheduler;

			//Définition de la liste des jours sélectionnés
			this.selectedScheduler.listeJours = this.listeJours.map((jour,index) => ({ jour,index })).filter(j => j.jour.isSelected).map(j => j.index) || [];
		} else
			//Suppression du scheduler
			this.selectedScheduler = null;

		//Fermeture de la popup
		this.bsModalRef.hide();
	}
}